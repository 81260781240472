import { FC } from "react";
import { pickerStylesError, pickerStyles } from "../datePicker/pickerStyles";
import SgDateTimePickerBase from "./SgDateTimePickerBase";

interface SgDateTimePickerProps {
  utcValue: Date | null;
  setUtcValue: (value: Date | null) => void;
  label: string;
  className?: string;
  error?: boolean;
  disableFuture?: boolean;
}

export const SgDateTimePicker: FC<SgDateTimePickerProps> = (props) =>
  SgDateTimePickerBase({
    ...props,
    disableFuture: props.disableFuture ?? false,
    slotProps: {
      textField: (params) => ({
        ...params,
        sx: props.error ? pickerStylesError : pickerStyles,
        error: props.error,
      }),
    },
  });

export const SgLegacyDateTimePicker: FC<SgDateTimePickerProps> = (props) =>
  SgDateTimePickerBase(props);
