export interface IFaucetCommandResultsMap {
    [key: number]: number;
}

export interface IFaucetCommandResponse {
    results: IFaucetCommandResultsMap;
}

export enum OpenFaucetCommandResultCode {
    Undefined = 0,
    Success = 1,
    NoSuchFaucet = 2,
    UnexpectedError = 3,
}

export enum CloseFaucetCommandResultCode {
    Undefined = 0,
    Success = 1,
    NoSuchFaucet = 2,
    UnexpectedError = 3,
}