export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const dayOfWeekToStringMap = {
  [DayOfWeek.Sunday]: "Su",
  [DayOfWeek.Monday]: "Mo",
  [DayOfWeek.Tuesday]: "Tu",
  [DayOfWeek.Wednesday]: "We",
  [DayOfWeek.Thursday]: "Th",
  [DayOfWeek.Friday]: "Fr",
  [DayOfWeek.Saturday]: "Sa",
};

export const dayOfWeekValues = Object.values(DayOfWeek).filter(
  (value): value is DayOfWeek => typeof value === "number"
);

export enum ScheduledMaintenanceType {
  OneTime = "One Time",
  Recurring = "Recurring",
}

export interface IRecurrence {
  repeatEveryWeek: number;
  daysInWeek: number;
  startTime: string;
  endTime: string;
}

export interface IScheduledMaintenance {
  scheduleType: ScheduledMaintenanceType;
  siteId: string;
  reason?: string;
  id: string;
  trackerIds: number[];
  targetElevation: number;
  startTimeUtc: number;
  endTimeUtc: number;
  recurrence?: IRecurrence;
  createdBy: string;
}

export interface IRecurrenceDto {
  repeatEveryWeek: number;
  daysInWeek: number;
  startTime: string;
  endTime: string;
}
export interface IScheduledMaintenanceDto {
  siteId: string;
  reason?: string;
  id: string;
  trackerIds: number[];
  targetElevation: number;
  startTimeUtc: number;
  endTimeUtc: number;
  recurrence?: IRecurrenceDto;
  createdBy: string;
}

export interface IAddScheduledMaintenanceDto {
  siteId: string;
  reason: string;
  trackerIds: number[];
  targetElevation: number;
  startTimeUtc: number;
  endTimeUtc: number;
  recurrence: IRecurrenceDto | null;
}

export interface IScheduledMaintenanceRecurringInput {
  repeatEveryWeek: number;
  selectedDays: number[];
  startTime: string;
  endTime: string;
  fromDateUtc: number;
  untilDateUtc: number;
}
export interface IScheduledMaintenanceOneTimeInput {
  startDateTimeUtc: number;
  endDateTimeUtc: number;
}

export interface IScheduledMaintenanceOverlapResult {
  overlappingScheduledMaintenanceId: string;
  overlappingTrackerIds: number[];
}

export interface IAddScheduledMaintenanceResponse {
  success?: IScheduledMaintenance;
}
