import { useSelector } from "react-redux";
import classes from "./TrackersCommand.module.css";
import TrackersControlCommandForm from "./TrackersControlCommandForm";
import { RootState } from "../../app/Store";
import commandsSchema from "./TrackerCommandsSchema";
import { TrackersCommandType } from "../TrackersModels";
import { selectFieldState } from "../../app/store/MultisiteTagsStore";
import { FieldState } from "../../data_point/models/TagsModels";

interface TrackersCommandProps {
  selectedTrackerIds: number[];
}
const TrackersCommand = ({ selectedTrackerIds }: TrackersCommandProps) => {
  const userType = useSelector((state: RootState) => state.user.userType);
  const anyCommandAllowed = Object.keys(commandsSchema).some(
    (key) =>
      userType >= commandsSchema[key as TrackersCommandType].minimumUserRole
  );
  const fieldState = useSelector((state: RootState) =>
    selectFieldState(state, state.site.siteId)
  );
  const isFieldDisconnected = fieldState === FieldState.Disconnected;
  return (
    <div className={classes["commands-form"]}>
      <TrackersControlCommandForm
        selectedTrackerIds={selectedTrackerIds}
        disabled={
          selectedTrackerIds.length === 0 ||
          !anyCommandAllowed ||
          isFieldDisconnected
        }
      />
    </div>
  );
};

export default TrackersCommand;
