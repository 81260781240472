import { useSelector } from "react-redux";

import { NavItemsConfig } from "./DrawerConfiguration";
import { RootState } from "../../app/Store";
import NavigationDrawer from "../NavigationDrawer/NavigationDrawer";
import { selectSiteId } from "../../sites/SiteStore";

export default function SiteNavigationDrawer() {
  const siteId = useSelector(selectSiteId);
  const currentSiteMetadta = useSelector((state: RootState) => state.multiSitesMetadata.sites[siteId]);
  const userLevel = useSelector((state: RootState) => state.user.userType);
  const filteredDrawerItems = NavItemsConfig.filter(item => item.visiblityFilter(userLevel, currentSiteMetadta));
  const editedSiteName = "/" + encodeURI(siteId);
  return (
    <NavigationDrawer
      navigationItems={filteredDrawerItems}
      navigationPrefx={editedSiteName}
    />
  );
}
