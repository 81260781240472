import { FC } from "react";

import classes from "./IconLoader.module.css";
import { useLoaderTimeout } from "../../common/Hooks/useLoaderTimeout";

interface LoaderProps {
  children?: React.ReactNode;
  isLoading: boolean;
}

const IconLoader: FC<LoaderProps> = ({ isLoading, children }) => {
  const show = useLoaderTimeout(!isLoading);

  return (
    <div className={classes["loader-container"]}>
      {show && (
        <>
          <div className={classes["color-overlay"]}> </div>
          <div className={classes["loader-spinner"]}></div>
        </>
      )}
      {children}
    </div>
  );
};
export default IconLoader;
