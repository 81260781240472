import { FunctionComponent, SVGProps } from "react";

import { IListItem } from "../../SolarGikLib/Lists/ListModels";
import { UserAccessType } from "../user/UserStore";

export const McsCommandNotImplementedStatusCode = 501;

export interface ITrackersCommandResultsMap {
  [key: string]: number;
}

export interface ITrackersCommandResponse {
  results: ITrackersCommandResultsMap;
}

export enum TrackersStateEnum {
  NotAvailable = -2,
  Disconnected = -1,
  Undefined = 0,
  Init = 1,
  Homing = 2,
  Manual = 3,
  Tracking = 4,
  SmartTracking = 5,
  Sleep = 6,
  Safe = 7,
  Error = 8,
  Done = 9,
  InProgress = 10,
  Updater = 11,
  Snow = 12,
  WindySnow = 13,
}

export enum SendTrackersToMaintenanceResultCodeEnum {
  Unknown = 0,
  Success = 1,
  NoSuchTracker = 2,
  UnexpectedError = 3,
  TrackerCurrentlyNotConnectedButAddedToMaintenanceList = 4,
  TrackerInSafeButAddedToMaintenanceList = 5,
}

export enum SendTrackersToFreezeResultCodeEnum {
  Unknown = 0,
  Success = 1,
  NoSuchTracker = 2,
  UnexpectedError = 3,
  TrackerCurrentlyNotConnectedCouldNotFreeze = 4,
}

export enum ReleaseTrackersFromMaintenanceResultCodeEnum {
  Unknown = 0,
  Success = 1,
  NoSuchTracker = 2,
  UnexpectedError = 3,
  TrackerCurrentlyNotConnectedButReleasedFromToMaintenanceList = 4,
  TrackerWasNotInMaintenance = 5,
}

export enum ResetTrackersResultCodeEnum {
  Unknown = 0,
  Success = 1,
  NoSuchTracker = 2,
  UnexpectedError = 3,
  TrackerCurrentlyNotConnectedCouldNotReset = 4,
}

export enum SendTrackersToHomingResultCodeEnum {
  Unknown = 0,
  Success = 1,
  NoSuchTracker = 2,
  UnexpectedError = 3,
  TrackerCurrentlyNotConnectedUnableToSendToHoming = 4,
  SafeModeIsActiveUnableToSendToHoming = 5,
  TrackerDidNotFinishHomingCommissioningUnableToSendToHoming = 6,
  TrackerInMaintenanceUnableToSendToHoming = 7,
}

export enum ForceTrackersElevationResultCodeEnum {
  Unknown = 0,
  Success = 1,
  NoSuchTracker = 2,
  UnexpectedError = 3,
  TrackerCurrentlyNotConnectedCouldNotForceElevation = 4,
}

export enum TrackersCommandType {
  ReleaseTrackersFromMaintenance = "Release",
  SendTrackersToMaintenance = "Manual",
  SendTrackersToFreeze = "Freeze",
  ResetTrackers = "Reset",
  SendTrackersToHoming = "Homing",
  ForceTrackersElevation = "Set Elevation",
}

export enum TrackersCommandReasonType {
  HighWindWeatherProtection = "Weather Protection High Wind",
  HighSnowWeatherProtection = "Weather Protection High Snow",
  PanelWashing = "Panel Washing",
  TrackerMaintenance = "Tracker Maintenance",
  ClientMaintenance = "Client Maintenance",
  SoftwareUpgrade = "Software Upgrade",
}

export enum MaintenanceReason {
  WeatherProjectionWind = 1,
  WeatherProjectionSnow = 2,
  PanelWashing = 3,
  TrackerMaintenance = 4,
  UserMaintenance = 5,
  SoftwareUpdate = 6,
}

export enum ChargingStateEnum {
  Undefined = 0,
  Unknown = 1,
  Charging = 2,
  Charged = 3,
  UsingBattery = 4,
  ChargingError = 5,
}

export interface ITrackersState {
  trackersStatus: ISiteToTrackersState;
  loading: boolean;
}

export interface ISiteToTrackersState {
  [siteId: string]: ITrackerStatus[];
}

export interface ITrackerStatus {
  updateTime: number;
  id: number;
  name: string;
  uptime?: number;
  currentState?: number;
  targetState?: number;
  currentElevation?: number;
  shadingRatio?: number;
  rssi?: number;
  chargingState?: ChargingStateEnum;
  chargePercentage?: number;
}

export interface ITrackerMetadata {
  macAddress: string;
  ip: string;
  xPosition: number;
  yPosition: number;
}

export enum ElevationDirections {
  West = "West",
  East = "East",
}

export interface TrackerElevation {
  value: number;
  direction: ElevationDirections;
}

export enum HomingDirection {
  West = 0,
  East = 1,
}

export interface IMaintenanceListitem extends IListItem {
  title: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  accessType: UserAccessType;
}
