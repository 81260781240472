import { FC, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../features/app/Store";
import useSetupUserInfo from "../features/user/useSetupUserInfo";
import useSetupTagsInfo from "../features/setup/useSetupTagsInfo";
import InitAppError from "./InitAppError";

import { fetchFeatureFlags } from "../features/featureFlags/SitesFeatureFlagsStore";

interface IProps {
  loadingIcon: ReactNode;
  children: ReactNode;
}
const InitApp: FC<IProps> = ({ loadingIcon, children }) => {
  useSetupUserInfo();
  const dispatch = useDispatch<AppDispatch>();
  const userData = useSelector((state: RootState) => state.user);
  const siteIds = userData.siteIds;

  useEffect(() => {
    dispatch(fetchFeatureFlags(siteIds));
  }, [siteIds]);
 
  const { isLoading: isLoadingTagsMetadata, error: errorLoadingTagsMetadata } =
    useSetupTagsInfo({ siteIds });
  const isLoadingFeatureFlags = useSelector(
    (state: RootState) => state.featureFlags.isLoading
  );
  const errorLoadingFeatureFlags = useSelector(
    (state: RootState) => state.featureFlags.error
  );
  const isLoading = isLoadingFeatureFlags || isLoadingTagsMetadata;
  const error = errorLoadingFeatureFlags || errorLoadingTagsMetadata;
  if (error) {
    return <InitAppError error={error} />;
  }
  return <>{isLoading ? loadingIcon : children}</>;
};
export default InitApp;
