export enum TrackerStateEnum {
    Disconnected = "Disconnected",
    Undefined = "Undefined",
    Init = "Init",
    Homing = "Homing",
    Manual = "Manual",
    Tracking = "Tracking",
    SmartTracking = "SmartTracking",
    Sleep = "Sleep",
    Safe = "Safe",
    Error = "Error",
    Done = "Done",
    InProgress = "InProgress",
    Updater = "Updater",
    Snow = "Snow",
    WindySnow = "WindySnow"
}

export enum WebUserAppLifeCycle
{
    NotReady = "NotReady",
    AppLoadedReadyForSiteSelection = "AppLoadedReadyForSiteSelection",
    SiteLoaded = "SiteLoaded",
}

export enum TrackerErrorType {
    Undefined = "Undefined",
    NoError = "NoError",
    Disconnected = "Disconnected",
    UnknownElevation = "UnknownElevation",
    IncompatibleFirmwareVersion = "IncompatibleFirmwareVersion",
    HasMotorError = "HasMotorError",
    HasNoClock = "HasNoClock",
    HomingNotDetected = "HomingNotDetected"
}


export class TrackerData {
    constructor(
        public trackerId: number,
        public state: TrackerStateEnum,
        public angle: number | null,
        public rssi: number | null,
        public lastUpdate: Date, //UTC date
        public errorType : TrackerErrorType ,
) {}
}

export enum LogType {
    Trace = 'Trace',
    Debug = 'Debug',
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error',
    Fatal = 'Fatal'
}

export class SimpleLogEvent {
    public readonly LogType: LogType;
    public readonly RawMsg: string;
    public readonly Args: string[];
    constructor(LogType: LogType, RawMsg: string, Args: string[]) {
        this.LogType = LogType;
        this.RawMsg = RawMsg;
        this.Args = Args;
    }
}