import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SgPopover from "../../../SolarGikLib/popover/SgPopover";
import getIcons from "../../../SolarGikLib/icons/Icons";
import {
  IconCategory,
  ScheduleCommandIcons,
} from "../../../SolarGikLib/icons/IconsModels";
import { AppDispatch, RootState } from "../../app/Store";
import { fetchScheduledMaintenance } from "./scheduleCommandStore";
import ScheduledMaintenanceTable from "./ScheduledMaintenanceTable";

interface IExistsScheduledCommandPopOverProps {
  siteId: string;
}

const ExistsScheduledCommandPopOver: FC<
  IExistsScheduledCommandPopOverProps
> = ({ siteId }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const existsScheduledMaintenance = useSelector(
    (state: RootState) => state.scheduleCommand.existingScheduledMaintenance
  );
  const loading = useSelector(
    (state: RootState) => state.scheduleCommand.loading
  );
  useEffect(() => {
    if (!open) {
      return;
    }
    dispatch(fetchScheduledMaintenance(siteId));
  }, [open]);

  const Icon = getIcons(
    ScheduleCommandIcons.ExistsScheduledCommand,
    IconCategory.ScheduleCommand
  );

  const togglePopover = () => {
    setOpen(!open);
  };
  return (
    <SgPopover
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={true}
      TriggerButton={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon onClick={togglePopover} />
        </div>
      }
      title={"Scheduled Trackers Manual action"}
    >
      <div
        style={{
          width: "95%",
          margin: "auto",
          marginTop: "50px",
        }}
      >
        <ScheduledMaintenanceTable
          scheduledMaintenance={existsScheduledMaintenance}
          loading={loading}
        />
      </div>
    </SgPopover>
  );
};
export default ExistsScheduledCommandPopOver;
