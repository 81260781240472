import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DiffuseOptimizationIcons,
  IconCategory,
} from "../../SolarGikLib/icons/IconsModels";
import getIcons from "../../SolarGikLib/icons/Icons";
import { RootState, AppDispatch } from "../app/Store";
import { getDiffuseOptimizationStatus } from "./DiffuseOptimizationStore";
import { repetitivePolling } from "../../common/AsyncUtils";
import APP_CONFIG from "../app/configuration/AppConfig";
import DarkTooltip from "../../SolarGikLib/tooltip/DarkTooltip";
import { selectFieldState } from "../app/store/MultisiteTagsStore";
import { FieldState } from "../data_point/models/TagsModels";

interface IDiffuseOptimizationStatusProps {
  siteId: string;
}

const DiffuseOptimizationIcon = getIcons(
  DiffuseOptimizationIcons.DiffuseOptimizationOn,
  IconCategory.DiffuseOptimization
);

const DiffuseOptimizationStatus: FC<IDiffuseOptimizationStatusProps> = ({
  siteId,
}: IDiffuseOptimizationStatusProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const isDiffuseOptimizationOn = useSelector(
    (state: RootState) => state.diffuseOptimization.isDiffuseOptimizationOn
  );
  const fieldState = useSelector((state: RootState) =>
    selectFieldState(state, siteId)
  );

  const fetchDiffuseOptimizationStatus = async () => {
    await dispatch(getDiffuseOptimizationStatus(siteId));
  };
  useEffect(() => {
    const controller = new AbortController();
    repetitivePolling(
      "diffuseOptimization",
      fetchDiffuseOptimizationStatus,
      APP_CONFIG.milliSecBetweenGetDiffuseOptimizationStatus,
      APP_CONFIG.milliSecBetweenFailedRequests,
      controller.signal
    );

    return () => {
      controller.abort();
    };
  }, [dispatch, siteId]);

  if (!(isDiffuseOptimizationOn && fieldState === FieldState.Tracking)) {
    return null;
  }

  return (
    <DarkTooltip title="Diffuse Irradiance Optimization Active">
      <div>
        <DiffuseOptimizationIcon />
      </div>
    </DarkTooltip>
  );
};

export default DiffuseOptimizationStatus;
