import { FC } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import { TrackerBadges } from "./TrackerBadges";
import { formatMaintenanceTime } from "./utils";
import { outlinedWhiteButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import SolarGikButton from "../../../SolarGikLib/Button";

interface IConflictDialogProps {
  onClose: () => void;
}

const ConflictDialog: FC<IConflictDialogProps> = ({ onClose }) => {
  const conflictsScheduledMaintenance = useSelector(
    (state: RootState) => state.scheduleCommand.conflictsScheduledMaintenance
  );

  const existingScheduledMaintenance = useSelector(
    (state: RootState) => state.scheduleCommand.existingScheduledMaintenance
  );

  const siteTimezone = useSelector(
    (state: RootState) => state.site.ianaTimeZoneName
  );

  if (!conflictsScheduledMaintenance) {
    return null;
  }

  const conflicts = conflictsScheduledMaintenance.map((conflict) => {
    const existingMaintenance = existingScheduledMaintenance.find(
      (em) => em.id === conflict.overlappingScheduledMaintenanceId
    );
    if (!existingMaintenance) {
      console.error(
        `No existing maintenance found with this ID ${conflict.overlappingScheduledMaintenanceId}`
      );
      return {
        ...conflict,
        existing: null,
      };
    }
    return {
      ...conflict,
      existing: existingMaintenance,
    };
  });

  return (
    <Dialog open={!!conflicts} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle color="error">Schedule Conflicts Detected</DialogTitle>
      <DialogContent>
        {conflicts.map((conflict) => (
          <div
            key={conflict?.overlappingScheduledMaintenanceId}
            style={{
              overflow: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flexDirection: "row",
              }}
            >
              <h4>
                From - To{" "}
                {conflict.existing &&
                  formatMaintenanceTime(conflict.existing, siteTimezone)}
              </h4>
              Trackers:{" "}
              <TrackerBadges trackerIds={conflict?.overlappingTrackerIds} />
            </div>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <SolarGikButton
          onClickFunc={onClose}
          text="Cancel"
          style={{
            ...outlinedWhiteButton,
            width: "110px",
            height: "47px",
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConflictDialog;
