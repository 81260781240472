import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  ITrackersState,
  TrackersStateEnum,
} from "../../trackers/TrackersModels";
import { getAllLatestTrackersAsync } from "../../trackers/TrackersAPI";
import { DevicesMap, TrackerName } from "../../sites/SiteModels";

const defaultTrackersState: ITrackersState = {
  trackersStatus: {},
  loading: false,
};

export const multiSitesTrackersSlice = createSlice({
  name: "multiSitesTrackers",
  initialState: defaultTrackersState,
  reducers: {
    onFieldStateDisconnected: (
      state,
      action: PayloadAction<{
        siteId: string;
        trackerIds: number[];
        trackerNames: DevicesMap<TrackerName | null | undefined>;
      }>
    ) => {
      const { siteId, trackerIds, trackerNames } = action.payload;
      const dateNow = Date.now();
      state.trackersStatus[siteId] = trackerIds.map((trackerId) => ({
        updateTime: dateNow,
        id: trackerId,
        name: trackerNames[trackerId] ?? trackerId.toString(),
        currentState: TrackersStateEnum.NotAvailable,
      }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrackerStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTrackerStatus.fulfilled, (state, action) => {
        const { site, trackerResponse } = action.payload;
        state.trackersStatus[site] = trackerResponse;
        state.loading = false;
      })
      .addCase(fetchTrackerStatus.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const fetchTrackerStatus = createAsyncThunk(
  "multiSitesTrackers/fetchStatus",
  async (siteId: string) => {
    const response = await getAllLatestTrackersAsync(siteId);
    return {
      site: siteId,
      trackerResponse: response,
    };
  }
);

export const { onFieldStateDisconnected } = multiSitesTrackersSlice.actions;
export const multiSitesTrackersReducer = multiSitesTrackersSlice.reducer;
