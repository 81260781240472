import { FC, useState } from "react";
import { differenceInMinutes, formatDistanceStrict } from "date-fns";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { TagValueType } from "./models/TagsModels";
import LightTooltipContent from "../../SolarGikLib/tooltip/LightTooltipContent";

import { RootState } from "../app/Store";
import {
  selectSiteTag,
  selectSiteTagDataStr,
} from "../app/store/MultisiteTagsStore";
import { invalidTagMetadata } from "../app/TagsMetadataUtils";
import { UserAccessType } from "../user/UserStore";
import { useInterval } from "../app/UseInterval";
import { DAYJS_YEAR_TO_SECOND_FORMAT } from "../app/DayjsUtils";

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = 60 * 24;
const MINUTES_TILL_DATE_IS_SHOWN = 60 * 12;
const NO_DATA_FOR_TIME = "No data";

function formatTimeForTooltip(sampleTime: Date, useTimeFormat: boolean) {
  const timeNow = new Date();
  if (sampleTime.getTime() <= 0) {
    return NO_DATA_FOR_TIME;
  }
  if (useTimeFormat) {
    return dayjs(sampleTime).format(DAYJS_YEAR_TO_SECOND_FORMAT);
  }
  if (
    Math.abs(differenceInMinutes(timeNow, sampleTime)) >=
    MINUTES_TILL_DATE_IS_SHOWN
  ) {
    let timeDescription = MINUTES_TILL_DATE_IS_SHOWN + " minutes ago";
    if (
      MINUTES_TILL_DATE_IS_SHOWN > MINUTES_IN_DAY &&
      MINUTES_TILL_DATE_IS_SHOWN % MINUTES_IN_DAY === 0
    ) {
      timeDescription =
        MINUTES_TILL_DATE_IS_SHOWN / MINUTES_IN_DAY + " days ago";
    } else if (
      MINUTES_TILL_DATE_IS_SHOWN > MINUTES_IN_HOUR &&
      MINUTES_TILL_DATE_IS_SHOWN % MINUTES_IN_HOUR === 0
    ) {
      timeDescription =
        MINUTES_TILL_DATE_IS_SHOWN / MINUTES_IN_HOUR + " hours ago";
    }
    return "More then " + timeDescription;
  }
  return formatDistanceStrict(sampleTime, timeNow) + " ago";
}

export interface TooltipContentProps {
  tagName: string;
  siteId: string;
  isShowDateOnly: boolean;
  isShowTagMetadataOnly: boolean;
  displayName?: string;
  descriptionOverride?: string;
  valueToStringFuncOverride?: (value: number) => string;
}

const DataPointTooltipContent: FC<TooltipContentProps> = ({
  displayName = null,
  tagName,
  isShowDateOnly,
  isShowTagMetadataOnly,
  siteId,
  valueToStringFuncOverride,
  descriptionOverride,
}) => {
  const tagMetadata = useSelector(
    (state: RootState) =>
      state.multiSitesMetadata.sites[siteId]?.tags[tagName] ??
      invalidTagMetadata
  );
  const tagValuesStr = useSelector((state: RootState) =>
    selectSiteTagDataStr(state, siteId, tagName)
  );
  const userType = useSelector((state: RootState) => state.user.userType);
  const tag = useSelector((state: RootState) =>
    selectSiteTag(state, siteId, tagName)
  );
  const updateFormattedTime = () =>
    formatTimeForTooltip(tagValuesStr.timestamp, false);
  const [formattedTime, setFormattedTime] = useState(updateFormattedTime);
  useInterval(() => setFormattedTime(updateFormattedTime()), 1000);

  let valueAsString: string;
  if (valueToStringFuncOverride != null) {
    valueAsString = valueToStringFuncOverride(tag.value);
  } else if (!tagMetadata.toStringConverter) {
    valueAsString = tagValuesStr.value;
  } else {
    valueAsString = tagMetadata.toStringConverter(tag.value);
  }
  const showValueWithEnum = tagMetadata.tagValueType === TagValueType.Enum;

  if (displayName == null) {
    displayName = tagMetadata.displayName ?? tagName;
  }

  const hasUnit = tagMetadata.unitName != null && tagMetadata.unitName !== "";

  const isEngineer = userType >= UserAccessType.Engineer;

  const tooltipInfo: string[] = [];

  function addTagMetadataInfo() {
    tooltipInfo.push(descriptionOverride ?? tagMetadata.description);
    if (isEngineer) {
      tooltipInfo.push("Tag: " + tagName);
      tooltipInfo.push("Type: " + TagValueType[tagMetadata.tagValueType]);
    }
  }

  function addTagValueInfo() {
    if (isEngineer) {
      let valueInfo = "";
      showValueWithEnum && (valueInfo += "Value: ");
      valueInfo += valueAsString;
      showValueWithEnum && (valueInfo += ` (${tag.value})`);
      hasUnit && (valueInfo += ` (${tagMetadata.unitName})`);
      tooltipInfo.push(valueInfo);
    }
    tooltipInfo.push(formattedTime);
    if (isEngineer && formattedTime !== NO_DATA_FOR_TIME) {
      tooltipInfo.push(formatTimeForTooltip(tagValuesStr.timestamp, true));
    }
  }

  if (!isShowDateOnly) {
    addTagMetadataInfo();
  }
  if (!isShowTagMetadataOnly) {
    addTagValueInfo();
  }

  return (
    <LightTooltipContent
      name={!isShowDateOnly ? displayName : undefined}
      info={tooltipInfo}
    />
  );
};

export default DataPointTooltipContent;
