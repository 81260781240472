import UnityManager, { IUnityLoadingProvider, }  from './unity/unityLoader';

import {waitForAsync}                       from "./assets/ts/utils";

import {ISolarSightProvider}                    from './assets/ts/iSolarSightProvider';
import { ISolarSightApi }                       from './assets/ts/iSolarSightApi';

import { WebUserAppLifeCycle }                  from './assets/ts/solarSightModels';

import {WrapperSolarSightProvider} from './assets/ts/wrapperSolarSightProvider';
import {SolarSightApi}                          from './assets/ts/solarSightApi';

export type {ISolarSightApi}        from './assets/ts/iSolarSightApi';
export type {ISolarSightProvider}   from './assets/ts/iSolarSightProvider';
export {
    TrackerStateEnum,
    TrackerData,
    TrackerErrorType,
    WebUserAppLifeCycle,
    LogType,
    SimpleLogEvent,
}                                   from './assets/ts/solarSightModels';

export const initSolarSightAsync = async function (
    provider: ISolarSightProvider)
    : Promise<ISolarSightApi>
{
    console.info("initSolarSightAsync.start");

    const providerWrapper = new WrapperSolarSightProvider(provider);
    const unityLoadingProvider =
        new DefaultUnityLoadingHandler(
                provider.getCanvas());

    const waitForDOMContentLoadedAsync = (): Promise<void> => {
        return new Promise<void>(() => {
            document.addEventListener('DOMContentLoaded', () => {
                //just wait for the event
            });
        });
    };

    if (document.readyState === 'loading') {
        await waitForDOMContentLoadedAsync();
    }

    console.debug("initSolarSightAsync.waiting initUnityAsync (download .br)");
    const unityManager = new UnityManager(unityLoadingProvider);
    const unityInstance = await unityManager.initUnityAsync();

    if(unityInstance === null) {
        providerWrapper.cleanup();
        throw new Error("Unity not initialized");
    }

    const iSolarSightApi = new SolarSightApi(unityInstance, providerWrapper);

    //Cleanup
    unityManager.addCleanUpDelegate(
        ()=>providerWrapper.cleanup());
    unityManager.addCleanUpDelegate(
        ()=>iSolarSightApi.cleanup());

    console.debug("initSolarSightAsync.waiting for unity to finish init (call smd)");
    await waitForAsync( () => providerWrapper.getAppState() === WebUserAppLifeCycle.AppLoadedReadyForSiteSelection);
    console.debug("initSolarSightAsync.done");
    return iSolarSightApi;
};

class DefaultUnityLoadingHandler implements IUnityLoadingProvider{
    constructor(private _canvas:HTMLCanvasElement){
    }

    public onLoadProgress = function(fraction:number): void{
        console.debug("LoadingValue",fraction);
    }

    public solarSightUnityPrefixPath = "";

    public getSolarSightCanvas = (): HTMLCanvasElement =>{
        return this._canvas;
    }
}