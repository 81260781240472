// src/UnityManager.ts

import {} from "../global";

export interface IProgressHandler {
    (fraction: number): void;
}

export interface IUnityLoadingProvider {
    onLoadProgress: IProgressHandler | null;
    solarSightUnityPrefixPath: string;
    getSolarSightCanvas: () => HTMLCanvasElement;
}

export interface UnityInstance {
    canvas: HTMLCanvasElement;
    SendMessage: (gameObject: string, methodName: string, parameter: string|undefined) => void;
    Quit: () => void;
}

export type OnCleanUpDelegate = () => void;

export default class UnityManager {
    #unityLoadingHandler: IUnityLoadingProvider;
    #unityInstance: UnityInstance | null = null;
    #onCleanUp: OnCleanUpDelegate[] = [];
    #mutationObserver: MutationObserver | null = null;

    constructor(unityLoadingHandler: IUnityLoadingProvider) {
        this.#unityLoadingHandler = unityLoadingHandler;
    }

    public async initUnityAsync(): Promise<UnityInstance> {
        const onLoadProgress: IProgressHandler = this.#unityLoadingHandler.onLoadProgress || ((fraction) => {
            console.log(`Loading progress: ${(fraction * 100).toFixed(2)}%`);
        });

        const prefixPath = `${this.#unityLoadingHandler.solarSightUnityPrefixPath}/unity`;
        const streamingAssetsFolder = `${prefixPath}/StreamingAssets`;
        const buildFolder = `${prefixPath}/Build`;

        console.debug("Unity initialization started...", { prefixPath, streamingAssetsFolder, buildFolder });

        const canvas = this.#unityLoadingHandler.getSolarSightCanvas();
        if (!canvas) {
            const errorMsg = "Unity canvas element not found.";
            console.error(errorMsg);
            throw new Error(errorMsg);
        }

        const loaderUrl = `${buildFolder}/SolarSight.loader.js`;

        await this.loadScript(loaderUrl);
        console.log(">>>> Solar sight app version is:", (window as any).AppVersion);

        const config: any = {
            arguments: [],
            dataUrl: `${buildFolder}/SolarSight.data.br`,
            frameworkUrl: `${buildFolder}/SolarSight.framework.js.br`,
            codeUrl: `${buildFolder}/SolarSight.wasm.br`,
            streamingAssetsUrl: streamingAssetsFolder,
            companyName: "SolarGik",
            productName: "SolarSight",
            productVersion: AppVersion,
            showBanner: false,
            cacheControl: (url: string): string => {
                if (url.match(/\.data/) || url.match(/\.bundle/)) {
                    return "must-revalidate";
                }
                return "no-store";
            },
        };

        this.#unityInstance = await createUnityInstance(canvas, config, onLoadProgress);
        if(!this.#unityInstance) {
            throw new Error("Failed to create Unity instance.");
        }

        this.#unityInstance.canvas = canvas;

        this.setupCleanupObserver();

        console.debug("Unity instance created successfully.");
        return this.#unityInstance;
    }

    public addCleanUpDelegate(delegate: OnCleanUpDelegate): void {
        this.#onCleanUp.push(delegate);
    }
    public removeCleanupDelegate(delegate: OnCleanUpDelegate): void {
        this.#onCleanUp = this.#onCleanUp.filter(l => l !== delegate);
    }

    private cleanup(): void {
        if (this.#unityInstance) {
            this.#unityInstance.Quit();
            this.#unityInstance = null;
        }

        if (this.#mutationObserver) {
            this.#mutationObserver.disconnect();
            this.#mutationObserver = null;
        }

        this.#onCleanUp.forEach(delegate => delegate());
        this.#onCleanUp = [];
    }

    private loadScript(src: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const existingScript = document.querySelector<HTMLScriptElement>(`script[src="${src}"]`);
            if (existingScript) {
                console.log(`Script already loaded: ${src}`);
                resolve();
                return;
            }

            // @ts-ignore
            const script:any = document.createElement<HTMLScriptElement>('script');
            script.src = src;
            script.async = true;

            script.onload = () => resolve();
            script.onerror = () => reject(new Error(`Failed to load script: ${src}`));

            document.body.appendChild(script);
        });
    }

    private setupCleanupObserver(): void {
        if (!this.#unityInstance) return;

        const parent = this.#unityInstance.canvas.parentNode;
        if (!parent) {
            console.warn("Canvas has no parent node to observe for removal.");
            return;
        }

        this.#mutationObserver = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                for (const removedNode of mutation.removedNodes) {
                    if (removedNode === this.#unityInstance?.canvas) {
                        console.log(`Canvas '${this.#unityInstance.canvas.id}' removed, performing cleanup.`);
                        this.cleanup();
                        return;
                    }
                }
            }
        });

        this.#mutationObserver.observe(parent, { childList: true });
    }
}