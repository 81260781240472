import { GridRenderCellParams } from "@mui/x-data-grid";

import CellItem from "./CellItem";
import classes from "./CellItemEfficiency.module.css";
import ProgressBarSvg from "./ProcessBar";
import { TableCellSkeletonWrapper } from "../../../common/Mui/TableCellTextSkeletonWrapper";
import { TagTimeValidity } from "../../data_point/models/TagsModels";

const ProgressBarWidth = 60;

const calculateOverflow = (value: number) =>
  value > 100 ? Math.min(100, value - 100) : 0;

const normalizeProgress = (value: number) =>
  (Math.max(Math.min(100, value), 0) / 100) * ProgressBarWidth;

const CellItemEfficiency: React.FC<GridRenderCellParams> = (params) => {
  return (
    <TableCellSkeletonWrapper isDataExists={params.row[params.field] != null}>
      <div className={classes.container}>
        <div className={classes.text}>
          <CellItem {...params} />
        </div>
        {params.row[params.field]?.timeValidity != TagTimeValidity.Invalid && (
          <div className={classes["perfIndex"]}>
            <ProgressBarSvg
              width={ProgressBarWidth}
              progress={normalizeProgress(params.value)}
              overFlow={calculateOverflow(params.value)}
            />
          </div>
        )}
      </div>
    </TableCellSkeletonWrapper>
  );
};

export default CellItemEfficiency;
