import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/Store";
import classes from "./ScheduleCommand.module.css";
import { setOneTimeScheduleData } from "./scheduleCommandStore";
import { SgDateTimePicker } from "../../../SolarGikLib/datetimepicker/SgDateTimePicker";

const OneTimeSchedule = () => {
  const dispatch = useDispatch();
  const oneTimeScheduleData = useSelector(
    (state: RootState) => state.scheduleCommand.oneTimeScheduleData
  );
  const error = useSelector(
    (state: RootState) => state.scheduleCommand.oneTimeError
  );

  const handleStartTimeChange = (newValue: Date | null) => {
    if (newValue) {
      dispatch(
        setOneTimeScheduleData({
          startDateTimeUtc: newValue.getTime(),
          endDateTimeUtc: oneTimeScheduleData.endDateTimeUtc,
        })
      );
    }
  };

  const handleEndTimeChange = (newValue: Date | null) => {
    if (newValue) {
      dispatch(
        setOneTimeScheduleData({
          startDateTimeUtc: oneTimeScheduleData.startDateTimeUtc,
          endDateTimeUtc: newValue.getTime(),
        })
      );
    }
  };

  return (
    <>
      <div className={classes["form-container"]}>
        <SgDateTimePicker
          label="Start Time"
          utcValue={new Date(oneTimeScheduleData.startDateTimeUtc)}
          setUtcValue={handleStartTimeChange}
          error={!!error}
        />
        <SgDateTimePicker
          label="End Time"
          utcValue={new Date(oneTimeScheduleData.endDateTimeUtc)}
          setUtcValue={handleEndTimeChange}
          error={!!error}
        />
      </div>
      <div className={classes["error-message-container"]}>
        {error && <div className={classes["error-message"]}>{error}</div>}
      </div>
    </>
  );
};

export default OneTimeSchedule;
