import { useEffect, useState } from "react";
import Card from "../../SolarGikLib/cards/Card";
import classes from "./SprinklerControl.module.css";
import { getSpecificTagsLatestAsync } from "../app/TagsAPI";
import { IStringToTimeValueDictionary } from "../data_point/models/TagsModels";
import { repetitivePolling } from "../../common/AsyncUtils";
import APP_CONFIG from "../app/configuration/AppConfig";
import { closeFaucetsAsync, openFaucetsAsync } from "./SprinklersAPI";
import { TagsFormatPrefix } from "../data_point/TagsNames";
import SolarGikButton from "../../SolarGikLib/Button";
import { narrowOutlinedWhiteButton } from "../../SolarGikLib/styles/ButtonsStyle";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import Dialog from "../../SolarGikLib/dialogs/Dialog";
import { CloseFaucetCommandResultCode, OpenFaucetCommandResultCode } from "./sprinklersModels";
import getIcons from "../../SolarGikLib/icons/Icons";
import { IconCategory, SomaIcons } from "../../SolarGikLib/icons/IconsModels";
import { AlertMessage } from "../../SolarGikLib/alerts/AlertModels";
import { SprinklerDurationSelector } from "./SprinklerDurationSelector";

interface ISprinklerControlProps {
    siteId: string;
    sprinklerId: number;
    setAlertMessage: (message?: AlertMessage) => void;
}

const SprinklerControl = ({ siteId, sprinklerId, setAlertMessage }: ISprinklerControlProps) => {
    const [isFaucetOpen, setIsFaucetOpen] = useState<boolean>(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [requestStatus, setRequestStatus] = useState<'pending' | 'idle'>('idle');

    const SprinklerOnIcon = getIcons(SomaIcons.SprinklerOn, IconCategory.Soma);
    const SprinklerOffIcon = getIcons(SomaIcons.SprinklerOff, IconCategory.Soma);

    const setPostCommandAlertMessage = <T,>(
        commandResult: T,
        successValue: T,
        noSuchFaucetValue: T,
    ): void => {
        if (commandResult === successValue) {
            setAlertMessage({
                text: "Command sent successfully, it might take a few moments for the status to be reflected",
                severity: "success",
            });
            return;
        }
        if (commandResult === noSuchFaucetValue) {
            setAlertMessage({
                text: `Apologies, Sprinkler ${sprinklerId} doesn't exist`,
                severity: "error",
            });
            return;
        }
        setAlertMessage({
            text: `Apologies, Something went wrong with sprinkler ${sprinklerId}`,
            severity: "error",
        });
    };

    const sendOpenFaucetCommand = async (durationMs: number) => {
        const commandResult = await openFaucetsAsync(siteId, durationMs, [sprinklerId]);
        setRequestStatus('idle');
        setPostCommandAlertMessage(commandResult[sprinklerId], OpenFaucetCommandResultCode.Success, OpenFaucetCommandResultCode.NoSuchFaucet);
    }

    const sendCloseFaucetCommand = async () => {
        const commandResult = await closeFaucetsAsync(siteId, [sprinklerId]);
        setRequestStatus('idle');
        setPostCommandAlertMessage(commandResult[sprinklerId], CloseFaucetCommandResultCode.Success, CloseFaucetCommandResultCode.NoSuchFaucet);
    }

    const fetchFaucetOpeningRatio = async () => {
        const response: IStringToTimeValueDictionary =
            await getSpecificTagsLatestAsync(siteId, [TagsFormatPrefix.FAUCET_OPENING_RATIO + sprinklerId]);
        if (response) {
            const value = Object.values(response)[0];
            if (value.value > 0) {
                setIsFaucetOpen(true);
            } else {
                setIsFaucetOpen(false);
            }
        }
    }

    useEffect(() => {
        const cntrlr = new AbortController();
        repetitivePolling(
            "fetchFaucetOpeningRatio",
            fetchFaucetOpeningRatio,
            APP_CONFIG.milliSecBetweenGetTags,
            APP_CONFIG.milliSecBetweenFailedRequests,
            cntrlr.signal
        );
        return () => {
            cntrlr.abort();
        };
    }, [siteId]);


    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAlertMessage(undefined);
        setAnchorEl(event.currentTarget);
        if (isFaucetOpen) {
            setRequestStatus('pending');
            sendCloseFaucetCommand();
        } else {
            setIsPopoverOpen(true);
        }
    }

    const onDurationSelection = (durationInMinutes: number) => {
        setIsPopoverOpen(false);
        setRequestStatus('pending');
        sendOpenFaucetCommand(durationInMinutes * 60000);
    }

    const isPendingRequest = requestStatus !== 'idle';

    const buttonStyle = {
        ...narrowOutlinedWhiteButton,
        color: "#1377E7",
        border: `2px solid #1377E7`,
        visibility: isPendingRequest ? "hidden" : "visible",
        margin: "0px",
        width: "fit-content",
        "@media screen and (max-width: 1600px)": {
            margin: "0px"
        }
    }

    const onDialogClose = () => {
        setAnchorEl(null);
        setIsPopoverOpen(false);
    }

    return (
        <div className={classes["sprinkler-control-container"]}>
            <Card containerStyle={isPendingRequest ? { backgroundColor: "#FAFAFA" } : undefined}>
                <div className={classes["sprinkler-control-content"]}>
                    <div className={classes["sprinkler-control-column-items"]}>
                        <div className={TextEnum.h1}> Sprinkler {sprinklerId}</div>
                        {isPendingRequest ?
                            <div className={TextEnum.h1}> Processing... </div> :
                            <div className={`${TextEnum.h4} ${classes["sprinkler-state"]}`}>
                                {isFaucetOpen ? "currently on" : "currently off"}
                            </div>
                        }
                        <SolarGikButton
                            text={isFaucetOpen ? "Turn off" : "Turn on"}
                            onClickFunc={onButtonClick}
                            style={buttonStyle}
                        />
                        <Dialog
                            isOpen={isPopoverOpen}
                            anchorEl={anchorEl}
                            anchorHorizontalPlacement="center"
                            handleClose={onDialogClose}
                            displayHeader={false}
                        >
                            <SprinklerDurationSelector onDurationSelection={onDurationSelection} />
                        </Dialog>
                    </div>
                    {!isPendingRequest && (
                        isFaucetOpen ? <SprinklerOnIcon /> : <SprinklerOffIcon />
                    )}
                </div>
            </Card >
        </div >
    )
}

export default SprinklerControl;