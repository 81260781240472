import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getIsDiffuseOptimizationOnAsync } from "./diffuseOptimizationApi";

interface DiffuseOptimizationState {
  isDiffuseOptimizationOn: boolean;
  siteId: string;
}

const initialState: DiffuseOptimizationState = {
  isDiffuseOptimizationOn: false,
  siteId: "",
};

export const diffuseOptimizationSlice = createSlice({
  name: "diffuseOptimization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDiffuseOptimizationStatus.fulfilled, (state, action) => {
        state.isDiffuseOptimizationOn = action.payload.isActive;
      })
      .addCase(getDiffuseOptimizationStatus.pending, (state, action) => {
        if (state.siteId !== action.meta.arg) {
          state.siteId = action.meta.arg;
          state.isDiffuseOptimizationOn = false;
        }
      });
  },
});

export const getDiffuseOptimizationStatus = createAsyncThunk(
  "diffuseOptimization/fetchStatus",
  async (siteId: string) => {
    const isActive = await getIsDiffuseOptimizationOnAsync(siteId);
    return {
      siteId,
      isActive,
    };
  }
);

export const diffuseOptimizationReducer = diffuseOptimizationSlice.reducer;
