import dayjs, { Dayjs } from "dayjs";
import APP_CONFIG from "../app/configuration/AppConfig";
import { ITagLineInfo } from "../data_point/models/TagsModels";
import { ISiteMetadata } from "../sites/SiteModels";
import TagsNames, { TagsFormatPrefix } from "../data_point/TagsNames";

export interface ChartParams {
  tags: ITagLineInfo[];
  fromDate: Dayjs;
  toDate: Dayjs;
  samplingInterval: string;
  siteId: string;
}

export const initialChartParams = {
  tags: [],
  fromDate: dayjs(),
  toDate: dayjs(),
  samplingInterval: "",
  siteId: "",
};

export function createChartConfig(tagTable: ITagLineInfo[], siteMetadata: ISiteMetadata): ChartParams {
  const siteTimeNow = dayjs().tz(siteMetadata.ianaTimeZoneName);
  const availableTags = tagTable.filter((tag) => tag.id in siteMetadata.tags);
  return {
    siteId: siteMetadata.siteId,
    tags: availableTags,
    fromDate: siteTimeNow.startOf("day").add(4, 'hours'),
    toDate: siteTimeNow.startOf("day").add(20, 'hours'),
    samplingInterval: APP_CONFIG.chartDefaultSamplingInterval,
  };
}

export function createWeatherChartConfig(siteMetadata: ISiteMetadata): ChartParams {
  const chartWeather: ITagLineInfo[] = [];
  chartWeather.push({
    id: TagsNames.DNI,
    isCombinedYAxis: true,
  });
  chartWeather.push({
    id: TagsNames.GHI,
    isCombinedYAxis: true,
  });
  chartWeather.push({
    id: TagsNames.TEMP,
    isCombinedYAxis: false,
  });
  chartWeather.push({
    id: TagsNames.WIND,
    isCombinedYAxis: false,
  });
  return createChartConfig(chartWeather, siteMetadata);
}

export function createWeatherForAgriChartConfig(siteMetadata: ISiteMetadata): ChartParams {
  const chartWeather: ITagLineInfo[] = [];
  chartWeather.push({
    id: TagsNames.WEATHER_HUMIDITY,
    isCombinedYAxis: true,
  });
  chartWeather.push({
    id: TagsNames.GHI,
    isCombinedYAxis: true,
  });
  chartWeather.push({
    id: TagsNames.TEMP,
    isCombinedYAxis: false,
  });
  chartWeather.push({
    id: TagsNames.WIND,
    isCombinedYAxis: false,
  });
  return createChartConfig(chartWeather, siteMetadata);
}

export function createIrradiancePowerChartConfig(siteMetadata: ISiteMetadata): ChartParams {
  const chartIrradiancePower: ITagLineInfo[] = [];
  chartIrradiancePower.push({
    id: TagsNames.DNI,
    isCombinedYAxis: true,
  });
  chartIrradiancePower.push({
    id: TagsNames.GHI,
    isCombinedYAxis: true,
  });
  chartIrradiancePower.push({
    id: TagsNames.SITE_DC_CURRENT_POWER,
    isCombinedYAxis: false,
  });
  return createChartConfig(chartIrradiancePower, siteMetadata);
}

export function createSoilChartConfig(siteMetadata: ISiteMetadata): ChartParams {
  const chartSoil: ITagLineInfo[] = [];
  chartSoil.push({
    id: TagsNames.SOIL_HUMIDITY,
    isCombinedYAxis: true,
  });
  chartSoil.push({
    id: TagsNames.SOIL_TEMP,
    isCombinedYAxis: true,
  });
  chartSoil.push({
    id: TagsNames.SOIL_PH,
    isCombinedYAxis: true,
  });
  return createChartConfig(chartSoil, siteMetadata);
}

export function createDailyProductionChartConfig(siteMetadata: ISiteMetadata): ChartParams {
  const chartPower: ITagLineInfo[] = [];
  chartPower.push({
    id: TagsNames.SITE_AC_CURRENT_POWER,
    isCombinedYAxis: true,
  });
  chartPower.push({
    id: TagsNames.MOMENT_POTENTIAL_POWER,
    isCombinedYAxis: true,
  });
  chartPower.push({
    id: TagsNames.MOMENT_CONTRACT_REQUIRED_POWER,
    isCombinedYAxis: true,
  });
  return createChartConfig(chartPower, siteMetadata);
}

export function createCurrentProductionChartConfig(ids: number[], siteMetadata: ISiteMetadata): ChartParams {
  const currentProductionChart: ITagLineInfo[] = [];
  currentProductionChart.push({
    id: TagsNames.SITE_AC_CURRENT_POWER,
    isCombinedYAxis: true,
  });
  currentProductionChart.push({
    id: TagsNames.SITE_DC_CURRENT_POWER,
    isCombinedYAxis: true,
  });
  fillProductionChartConfig(
    currentProductionChart,
    ids,
    getInverterAcPowerTagWithId,
    "Inverter Power"
  );
  return createChartConfig(currentProductionChart, siteMetadata);
}

export function createPerformanceChartConfig(siteMetadata: ISiteMetadata): ChartParams {
  const chartPerformance: ITagLineInfo[] = [];
  chartPerformance.push({
    id: TagsNames.DAILY_SP_PRODUCTION,
    isCombinedYAxis: false,
  });
  chartPerformance.push({
    id: TagsNames.MOMENT_POTENTIAL_POWER,
    isCombinedYAxis: false,
  });
  chartPerformance.push({
    id: TagsNames.DAILY_SP_EFFICIENCY,
    isCombinedYAxis: false,
  });
  return createChartConfig(chartPerformance, siteMetadata);
}

export function createTrackersChartConfig(ids: number[], siteMetadata: ISiteMetadata): ChartParams {
  const trackersChart: ITagLineInfo[] = [];
  fillTrackersChartConfig(trackersChart, ids, getTrackerCurrentElevationTagWithId);
  return createChartConfig(trackersChart, siteMetadata);
}

export function createShadingPercentChartConfig(ids: number[], siteMetadata: ISiteMetadata): ChartParams {
  const trackersChart: ITagLineInfo[] = [];
  fillTrackersChartConfig(trackersChart, ids, getTrackerShadingPercentTagWithId);
  return createChartConfig(trackersChart, siteMetadata);
}

function fillProductionChartConfig(
  currentProductionChart: ITagLineInfo[],
  ids: number[],
  tagFunc: (number: number) => string,
  displayName: string
) {
  if (typeof ids !== "undefined" && ids !== null) {
    ids.forEach((id) => {
      if (currentProductionChart.find((data) => data.id === tagFunc(id))) {
        return;
      }
      currentProductionChart.push({
        id: tagFunc(id),
        displayName: displayName + " " + id,
        isCombinedYAxis: true,
      });
    });
  }
}

function fillTrackersChartConfig(trackersChart: ITagLineInfo[], ids: number[], trackerFunc: (number: number) => string) {
  ids.forEach((id) => {
    trackersChart.push({
      id: trackerFunc(id),
      displayName: "Tracker " + id,
      isCombinedYAxis: true,
    });
  });
}

const getInverterAcPowerTagWithId = (num: number) =>
  TagsFormatPrefix.SITE_AC_CURRENT_POWER + num;
const getTrackerCurrentElevationTagWithId = (num: number) =>
  "TrackerStatus:CurrentElevation:" + num;
const getTrackerShadingPercentTagWithId = (num: number) =>
  "TrackerStatus:ShadingRatio:" + num;

export const AUXILIARIES_ISSUES_TITLE = "Auxiliaries Issues";
export const POWER_GENERATION_TITLE = "Power Generation";
export const CURRENT_PRODUCTION_TITLE = "Production";
export const WEATHER_TITLE = "Weather";
export const PERFORMANCE_TITLE = "Performance";
export const SHADING_TITLE = "Shading Percent";
export const TRACKER_ELEVATION_TITLE = "Trackers Elevations";
export const IRRADIANCE_POWER_TITLE = "Irradiance / Power";
export const SOIL_TITLE = "Soil";
export const CHART_DEFAULT_HEIGHT = 390;
export const CHART_HEIGHT_WITHOUT_LEGEND = 370;
