export enum FeatureFlags {
  SendTrackersToFreeze = "SendTrackersToFreeze",
  SendTrackersToHoming = "SendTrackersToHoming",
  ResetTrackers = "ResetTrackers",
  ForceTrackersElevation = "ForceTrackersElevation",
  TrackersPixelsView = "TrackersPixelsView",
  ScheduledMaintenance = "ScheduledMaintenance",
}

export interface ISitesFeatureFlagsDictionary {
  [siteId: string]: FeatureFlags[];
}

export const normalizeFeatureFlags = (
  apiResponse: ISitesFeatureFlagsDictionary
): ISitesFeatureFlagsDictionary => {
  return Object.entries(apiResponse).reduce((normalized, [siteId, flags]) => {
    const validFlags = flags.filter((flag) =>
      Object.values(FeatureFlags).includes(flag)
    );
    normalized[siteId] = validFlags;
    return normalized;
  }, {} as ISitesFeatureFlagsDictionary);
};
