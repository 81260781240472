export enum PageNames {
  Dashboard = "Dashboard",
  Trackers = "Trackers",
  History = "History",
  Alerts = "Alerts",
  Agriculture = "Agriculture",
  Configuration = "Configuration",
  Sprinklers = "Sprinklers",
  UserRecords = "UserRecords",
  Weather = "Weather",
  Trends = "Trends",
  CloudConfiguration = "CloudConfiguration",
  MultisiteTable = "MultisiteTable",
  Cameras = "Cameras"
}
