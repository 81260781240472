import { useSelector } from "react-redux";
import { TagTimeValidity } from "./models/TagsModels";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import { RootState } from "../app/Store";
import {
  selectSiteTag,
  selectSiteTagDataStr,
} from "../app/store/MultisiteTagsStore";
import { invalidTagMetadata } from "../app/TagsMetadataUtils";

const DataPointInfo = (
  tagName: string,
  siteId: string,
  titleOverride?: string,
  valueToStringFuncOverride?: (value: number) => string
) => {
  const tag = useSelector((state: RootState) =>
    selectSiteTag(state, siteId, tagName)
  );
  const tagValuesStr = useSelector((state: RootState) =>
    selectSiteTagDataStr(state, siteId, tagName)
  );
  const tagMetadata = useSelector(
    (state: RootState) =>
      state.multiSitesMetadata.sites[siteId]?.tags[tagName] ??
      invalidTagMetadata
  );

  let valueInString: string;
  if (tag.timeValidity == TagTimeValidity.Invalid) {
    valueInString = NO_VALUE_PLACEHOLDER;
  } else if (valueToStringFuncOverride) {
    valueInString = valueToStringFuncOverride(tag.value);
  } else if (tagMetadata.toStringConverter) {
    valueInString = tagMetadata.toStringConverter(tag.value);
  } else {
    valueInString = tagValuesStr.value;
  }
  const title = titleOverride ?? tagMetadata.displayName;
  const unit = tagMetadata.unitName;
  const valueExist = !!tag.valueExist;
  const tagTimeValidityStatus = tag.timeValidity;

  return {
    title,
    valueInString,
    unit,
    valueExist,
    tagTimeValidityStatus,
  };
};
export default DataPointInfo;
