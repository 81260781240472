import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { repetitivePolling } from "../common/AsyncUtils";
import APP_CONFIG from "../features/app/configuration/AppConfig";
import { AppDispatch, RootState } from "../features/app/Store";
import {
  fetchTrackerStatus,
  onFieldStateDisconnected,
} from "../features/app/store/TrackersStore";
import { TrackersStateEnum } from "../features/trackers/TrackersModels";
import { PixelsView } from "../features/trackers/pixelsView/PixelsView";
import { FeatureFlags } from "../features/featureFlags/FeatureFlagsModels";
import { ITab } from "../SolarGikLib/tabs/TabsModels";
import { SimpleSgTabs } from "../SolarGikLib/tabs/SgTabs";
import { TrackersTableView } from "../features/trackers/TrackersTableView";
import { is150PercentScreen } from "../common/WindowUtils";
import { selectFieldState } from "../features/app/store/MultisiteTagsStore";
import { FieldState } from "../features/data_point/models/TagsModels";
import { selectTrackerIds } from "../features/sites/SiteStore";

const Trackers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const siteMetadata = useSelector((state: RootState) => state.site);
  const featureFlags = useSelector(
    (state: RootState) =>
      state.featureFlags.sitesFeatureFlags[siteMetadata.siteId]
  );
  const trackerIds = useSelector(selectTrackerIds);
  const isLoading = useSelector(
    (state: RootState) => state.multiSitesTrackers.loading
  );
  const fieldState = useSelector((state: RootState) =>
    selectFieldState(state, siteMetadata.siteId)
  );
  const trackersStatuses = useSelector(
    (state: RootState) =>
      state.multiSitesTrackers.trackersStatus[siteMetadata.siteId]
  );
  const isFieldStateDisconnected = fieldState === FieldState.Disconnected;

  useEffect(() => {
    const cntrlr = new AbortController();
    if (isFieldStateDisconnected) {
      dispatch(
        onFieldStateDisconnected({
          siteId: siteMetadata.siteId,
          trackerIds,
          trackerNames: siteMetadata.trackers,
        })
      );
    } else {
      repetitivePolling(
        "pollingTrackers",
        pollingTrackers,
        APP_CONFIG.milliSecBetweenGetTrackers,
        APP_CONFIG.milliSecBetweenGetTrackers,
        cntrlr.signal
      );
    }
    return () => {
      cntrlr.abort();
    };
  }, [siteMetadata, isFieldStateDisconnected]);

  useEffect(() => {
    if (!isFieldStateDisconnected) {
      return;
    }
    const hasAvailableTrackers = trackersStatuses?.some(
      (tracker) => tracker.currentState !== TrackersStateEnum.NotAvailable
    );
    if (hasAvailableTrackers) {
      dispatch(
        onFieldStateDisconnected({
          siteId: siteMetadata.siteId,
          trackerIds,
          trackerNames: siteMetadata.trackers,
        })
      );
    }
  }, [trackersStatuses, isFieldStateDisconnected]);

  const pollingTrackers = async () => {
    dispatch(fetchTrackerStatus(siteMetadata.siteId));
  };

  const trackersViewTabs: ITab[] = [
    {
      label: "Table View",
      key: "tableView",
      content: <TrackersTableView isLoading={isLoading} />,
      isSingleItem: false,
    },
    {
      label: "Map View",
      key: "mapView",
      content: <PixelsView siteId={siteMetadata.siteId}/>,
      isSingleItem: false,
    },
  ];

  const is150Percent = is150PercentScreen();

  return featureFlags.includes(FeatureFlags.TrackersPixelsView) ? (
    <SimpleSgTabs
      tabs={trackersViewTabs}
      tabPanelStyle={{ height: is150Percent ? "80%" : "90%" }}
    />
  ) : (
    <TrackersTableView isLoading={isLoading} />
  );
};

export default Trackers;
