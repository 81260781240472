export const pickerStyles = {
  "& .MuiInputBase-root": {
    borderRadius: "30px",
    backgroundColor: "white",
    color: "var(--blue-color-primary)",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--blue-color-primary)",
    borderWidth: "1px",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--blue-color-primary)",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--blue-color-primary)",
  },
  "& .MuiInputLabel-root": {
    color: "var(--blue-color-primary)",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--blue-color-primary)",
  },
  "& .MuiSvgIcon-root": {
    color: "var(--blue-color-primary)",
  },
  "& .MuiInputBase-input": {
    color: "var(--blue-color-primary)",
  },
};

export const pickerStylesError = {
  ...pickerStyles,
  borderColor: "var(--error-color-validation)",
  color: "var(--error-color-validation)",
  "& .MuiInputLabel-root": {
    color: "var(--error-color-validation)",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--error-color-validation)",
  },
  "& .MuiSvgIcon-root": {
    color: "var(--error-color-validation)",
  },
  "& .MuiInputBase-input": {
    color: "var(--error-color-validation)",
  },
};
