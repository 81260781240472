import { alpha, styled, Theme } from "@mui/material/styles";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  gridClasses,
} from "@mui/x-data-grid";
import { COLORS } from "../../SolarGikLib/Colors";
import classes from "./DataGridTable.module.css";

const ODD_OPACITY = 0.2;
const border = "1px solid var(--table-outline-color)";
const hoverColor = "var(--table-on-hover-color)";

export const StyledDataGridTable = styled(DataGrid)(createTableTheme);

export function createTableTheme({ theme }: { theme: Theme }) {
  return {
    border: "none",
    color: COLORS.DARK_BLUE_PRIMARY,
    fontWeight: "var(--font-weight)",
    fontSize: "14px",

    [`& .${gridClasses.row}.odd`]: {
      "&:hover, &.Mui-hovered": {
        backgroundColor: hoverColor,
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: hoverColor,
          "@media (hover: none)": {
            backgroundColor: hoverColor,
          },
        },
      },
    },
    [`& .${gridClasses.row}.even`]: {
      "&:hover, &.Mui-hovered": {
        backgroundColor: hoverColor,
      },
    },
    [`& .${gridClasses.cell}`]: {
      borderRight: border,
      "&:last-child": {
        borderRight: "none",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "5px 10px",
      "@media screen and (max-width: 1600px)": {
        fontSize: "12px",
      },
    },
    [`& .${gridClasses.cell}.right-border`]: {
      "&:last-child": {
        borderRight: "none",
      },
    },

    [`& .${gridClasses.columnHeader}`]: {
      backgroundColor: "white",
      borderRight: border,
      borderBottom: border,
      "&:last-child": {
        borderRight: "none",
      },
      paddingLeft: "0px",
      fontWeight: "var(--font-bold-weight)",
      fontSize: "14px",
      "@media screen and (max-width: 1600px)": {
        fontSize: "12px",
      },
    },

    [`& .${gridClasses.columnHeader}.right-border-and-bottom`]: {
      "&:last-child": {
        borderRight: "none",
      },
      paddingLeft: "0px",
    },
    [`& .${gridClasses.columnHeader}.main-header`]: {
      "&:last-child": {
        borderRight: "none",
      },
      paddingLeft: "0px",
    },
    "& [class^=HeaderGroupItem_header-group-item]": {
      backgroundColor: "white",
    },
    " & .MuiDataGrid-menuIcon": {
      transitionDelay: "0.3s",
    },
    "& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon":
      {
        transition: "opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },

    "& .MuiDataGrid-columnHeaderTitleContainer": {
      boxSizing: "border-box",
      width: "100%",
      padding: "0px",
      margin: "0px",
      border: "0px !important",
      overflow: "visible",
    },
    [`& .MuiDataGrid-columnHeaderTitleContainerContent`]: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      padding: "0px",
      margin: "0px",
      border: "0px !important",
      overflow: "visible",
    },
    "& [class^='HeaderGroupItem_text']": {
      color: COLORS.DARK_BLUE_PRIMARY,
      fontSize: "14px",
      textDecoration: "underline",
    },
    "@media screen and (max-width: 1600px)": {
      "& [class^='HeaderGroupItem_text']": {
        fontSize: "12px",
      },
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      display: "flex",
      flexWrap: "wrap",
      border: "0px solid",
      borderColor: "var(--table-outline-color)",
    },

    "& .MuiSvgIcon-root": {
      color: COLORS.DARK_BLUE_PRIMARY,
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-main": {
      border: border,
      borderRadius: "var(--card-border-radius)",
    },
    "& .MuiDataGrid-overlay": {
      fontSize: "20px",
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
  };
}

const CustomGridToolbarContainer = styled(GridToolbarContainer)(
  ({ theme }) => ({
    backgroundColor: "transparent",
    marginBottom: theme.spacing(2),
    justifyContent: "flex-end",
    gap: "0",
  })
);
export const GridToolbarStyled = () => {
  return (
    <CustomGridToolbarContainer>
      <GridToolbarColumnsButton className={classes.blue} />
      <GridToolbarFilterButton className={classes.blue} />
      <GridToolbarDensitySelector className={classes.blue} />
      <GridToolbarExport
        className={classes.blue}
        printOptions={{ disableToolbarButton: true }}
      />
    </CustomGridToolbarContainer>
  );
};
