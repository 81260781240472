import { initSolarSightAsync } from "@solargik/solar-sight-webgl";
import { SolarSightProvider } from "./solarSightProvider";

export const solarSightProvider = new SolarSightProvider();

export async function initializePixelsCanvas(siteId: string, signal: AbortSignal) {
    const pixelsClient = await createPixelsClient(siteId, signal);
    if (!pixelsClient || signal.aborted) {
        return;
    }
    const intervalId = setInterval(() => {
        try {
            pixelsClient.refreshAsync();
        } catch (error) {
            // we can ignore errors that occur when the signal is aborted (canvas possibly unmounted) 
            if (!signal.aborted) {
                console.error(error);
            }
        }
    }, 5000);
    signal.addEventListener("abort", () => clearInterval(intervalId));
}

async function createPixelsClient(siteId: string, signal: AbortSignal) {
    // debounce for 100ms to avoid React's strict-mode initialization issues 
    // (specifically, we call web assembly code that relies on the exitence of canvas
    // element that might be unmounted by the time the initialization completes)
    await new Promise((resolve) => setTimeout(resolve, 100));
    try {
        signal.throwIfAborted();
        const solarSightApi = await initSolarSightAsync(solarSightProvider);
        signal.throwIfAborted();
        await solarSightApi.setSiteAsync(siteId);
        signal.throwIfAborted();
        await solarSightApi.refreshAsync();
        return solarSightApi;
    } catch (error) {
        if (!signal.aborted) {
            throw error;
        }
    }
}