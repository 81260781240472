import axios, { AxiosResponse } from "axios";

import APP_CONFIG from "../app/configuration/AppConfig";
import { CloseFaucetCommandResultCode, IFaucetCommandResponse, IFaucetCommandResultsMap, OpenFaucetCommandResultCode } from "./sprinklersModels";

export const uploadNewSprinklersPlanAsync = async (
  siteId: string,
  fileData: Uint8Array
): Promise<AxiosResponse> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiUploadSprinklersPlan(siteId);
  const params = {
    file: fileData,
  };
  const headers = { headers: { "Content-Type": "multipart/form-data" } };
  const res = await axios.post<AxiosResponse>(url, params, headers);
  return res;
};

export const deleteSprinklersPlanAsync = async (
  siteId: string
): Promise<boolean> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiDeleteSprinklersPlan(siteId);
  const res = await axios.delete<void>(url);
  return res.status == 204;
};

export const getPlanAsFileAsync = async (siteId: string) => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiDownloadSprinklersPlan(siteId);
  const res = await axios.get<string>(url);
  return res.data;
};

export const getSprinklersPlanTemplateAsync = async (
  templateVersionNumber: string
) => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetSprinklersPlanTemplateFile(templateVersionNumber);
  const res = await axios.get<string>(url);
  return res.data;
};

export const getIsPlanExist = async (siteId: string) => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiGetIsPlanExist(siteId);
  const res = await axios.get<boolean>(url);
  return res.data;
};

export const openFaucetsAsync = async (
  siteId: string,
  durationMs: number,
  faucetsIds: number[]
): Promise<IFaucetCommandResultsMap> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiOpenFaucets(siteId);
  const params = {
    DurationMs: durationMs,
    FaucetsIds: faucetsIds
  };
  try {
    const httpResponse = await axios.post<IFaucetCommandResponse>(url, params);
    return httpResponse.data.results;
  } catch (error) {
    return Object.fromEntries(faucetsIds.map((id) => [id, OpenFaucetCommandResultCode.UnexpectedError]));
  }
};

export const closeFaucetsAsync = async (
  siteId: string,
  faucetsIds: number[]
): Promise<IFaucetCommandResultsMap> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiCloseFaucets(siteId);
  try {
    const httpResponse = await axios.post<IFaucetCommandResponse>(url, faucetsIds);
    return httpResponse.data.results;
  } catch (error) {
    return Object.fromEntries(faucetsIds.map((id) => [id, CloseFaucetCommandResultCode.UnexpectedError]));
  }
};