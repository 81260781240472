import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ISitesFeatureFlagsDictionary, normalizeFeatureFlags } from './FeatureFlagsModels'
import getMultiSiteFeatureFlagsAsync from "./FeatureFlagsApi";

interface IFeatureFlagsState {
  sitesFeatureFlags: ISitesFeatureFlagsDictionary;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}

const initialState: IFeatureFlagsState = {
  sitesFeatureFlags: {},
  isLoading: false
}

const featuresSlice = createSlice({
  name: 'sitesFeatureFlags',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    .addCase(fetchFeatureFlags.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchFeatureFlags.fulfilled, (state, action) => {
      state.sitesFeatureFlags = action.payload;
      state.isLoading = false;
    })
    .addCase(fetchFeatureFlags.rejected, (state, action) => {
      state.error = action.error;
      state.isLoading = false;
    })
});

export const fetchFeatureFlags = createAsyncThunk(
  "sitesFeatureFlags/fetch",
  async (siteIds: string[]) => {
    const enabledFeaturesPerSiteResponse = await getMultiSiteFeatureFlagsAsync(siteIds);
    return normalizeFeatureFlags(enabledFeaturesPerSiteResponse);
  }
);

export default featuresSlice.reducer;