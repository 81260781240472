export interface SafeInterval {
    stop: () => void;
}

export function waitForAsync(
    getBoolFunction:  () => boolean | Promise<boolean>,
    interval: number = 100,   // Interval between checks in milliseconds
    timeout: number = 0    // 0 / negative = no timeout
): Promise<void> {
    return new Promise((resolve, reject) => {
        let timeoutHandle: ReturnType<typeof setTimeout> | null = null;

        if(timeout > 0) {
            timeoutHandle = setTimeout(() => {
                intervalHandle.stop();
                reject(new Error(`Timeout of ${timeout}ms exceeded while waiting for condition.`));
            }, timeout);
        }

        function invokeFunc() : boolean {
            try {
                if (getBoolFunction()) {
                    if(timeoutHandle != null) {
                        clearTimeout(timeoutHandle);
                        intervalHandle.stop();
                    }
                    resolve();
                    return true;
                }
                return false;
            } catch (error: any) {
                if(timeoutHandle != null) {
                    clearTimeout(timeoutHandle);
                    intervalHandle.stop();
                }
                reject(new Error(`API call failed: ${error.message}`));
                return true;
            }
        };
        const intervalHandle = setSmartInverval(() :void => {invokeFunc();}, interval);
        invokeFunc();
    });
}

export function delayAsync(miliSeconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, miliSeconds));
}

export function setSmartInverval(callback:
                                    () => void | Promise<void>,
                                 intervalInMiliSec: number,
                                 callOnStart:boolean = false): SafeInterval
{
    let timeoutId: ReturnType<typeof setTimeout> | null = null;
    let stopped = false;

    const executeCallback = async () => {
        if (stopped) return;

        try {
            await callback();
        } catch (error) {
            console.error("Error in safeSetInterval callback:", error);
        }

        if (!stopped) {
            timeoutId = setTimeout(executeCallback, intervalInMiliSec);
        }
    };
    timeoutId = setTimeout(executeCallback, intervalInMiliSec);

    if(callOnStart){
        try{
                callback();
        }catch(e) {
            console.error(e);
        }
    }

    return {
        stop: () => {
            stopped = true;
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        },
    };
}