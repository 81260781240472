import { GridColDef } from "@mui/x-data-grid";
import { convertNumberToTimeSpan } from "../../common/StringUtils";
import BatteryStatus from "./BatteryStatus";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import CurrentState from "./CurrentState";
import TrackerIssues from "./TrackerIssues";
import { TrackersStateEnum } from "./TrackersModels";
import { humanizeEnumValue } from "../../common/EnumUtils";

export const trackersTableColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1.4,
    renderCell: (params) => (
      <TrackerIssues
        trackerName={params.value.name}
        activeIssues={params.value.activeIssues}
      />
    ),
  },
  {
    field: "id",
    headerName: "ID",
    flex: 0.2,
    valueGetter: (params) => params.value ?? NO_VALUE_PLACEHOLDER,
  },
  {
    field: "currentState",
    headerName: "State",
    flex: 0.8,
    renderCell: (params) => (
      <CurrentState
        currentState={params.row.currentState.current}
        targetState={params.row.currentState.target}
      />
    ),
    valueGetter: (params) =>
      params.value.current == TrackersStateEnum.InProgress
        ? humanizeEnumValue(TrackersStateEnum[params.value.target]) ||
          NO_VALUE_PLACEHOLDER
        : humanizeEnumValue(TrackersStateEnum[params.value.current]) ||
          NO_VALUE_PLACEHOLDER,
  },
  {
    field: "currentElevation",
    headerName: "Elevation",
    flex: 0.6,
    valueGetter: (params) => params.value ?? NO_VALUE_PLACEHOLDER,
  },
  {
    field: "shadingRatio",
    headerName: "Shading Ratio",
    flex: 0.6,
    valueGetter: (params) => params.value ?? NO_VALUE_PLACEHOLDER,
  },
  {
    field: "batteryStatus",
    headerName: "Battery Status",
    flex: 1.3,
    renderCell: (params) => (
      <BatteryStatus
        chargePercentage={params.value.chargePercentage}
        chargingState={params.value.chargingState}
      />
    ),
  },
  {
    field: "uptime",
    headerName: "Uptime",
    flex: 0.7,
    valueGetter: (params) =>
      params.value != null
        ? convertNumberToTimeSpan(params.value)
        : NO_VALUE_PLACEHOLDER,
  },
];
