import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";
import {
  IAddScheduledMaintenanceDto,
  IScheduledMaintenance,
  IScheduledMaintenanceDto,
  ScheduledMaintenanceType,
  IScheduledMaintenanceOverlapResult,
  IScheduledMaintenanceRecurringInput,
  IScheduledMaintenanceOneTimeInput,
} from "./Models";
import {
  getExistingScheduledMaintenance,
  deleteScheduledMaintenance,
  addScheduledMaintenance,
} from "./API";

interface ScheduleCommandState {
  oneTimeScheduleData: IScheduledMaintenanceOneTimeInput;
  recurringScheduleData: IScheduledMaintenanceRecurringInput;
  oneTimeError: string | null;
  recurringError: string | null;
  existingScheduledMaintenance: IScheduledMaintenance[];
  loading: boolean;
  deleteErrorMessage?: string;
  addScheduledMaintenanceError?: string;
  conflictsScheduledMaintenance?: IScheduledMaintenanceOverlapResult[];
}
const now = new Date().getTime();
const initialState: ScheduleCommandState = {
  oneTimeScheduleData: {
    startDateTimeUtc: now,
    endDateTimeUtc: now,
  },
  recurringScheduleData: {
    repeatEveryWeek: 1,
    selectedDays: [],
    startTime: "",
    endTime: "",
    fromDateUtc: now,
    untilDateUtc: now,
  },
  oneTimeError: "",
  recurringError: "",
  existingScheduledMaintenance: [],
  loading: false,
  deleteErrorMessage: undefined,
  addScheduledMaintenanceError: undefined,
};

const ScheduleCommandSlice = createSlice({
  name: "scheduleCommand",
  initialState,
  reducers: {
    setOneTimeScheduleData(
      state,
      action: PayloadAction<IScheduledMaintenanceOneTimeInput>
    ) {
      state.oneTimeScheduleData = action.payload;
      state.oneTimeError = null;
    },
    setRecurringScheduleData(
      state,
      action: PayloadAction<IScheduledMaintenanceRecurringInput>
    ) {
      state.recurringScheduleData = action.payload;
      state.recurringError = null;
    },
    setOneTimeError(state, action: PayloadAction<string>) {
      state.oneTimeError = action.payload;
    },
    setRecurringError(state, action: PayloadAction<string>) {
      state.recurringError = action.payload;
    },
    clearDeleteError(state) {
      state.deleteErrorMessage = undefined;
    },
    clearAddError(state) {
      state.addScheduledMaintenanceError = undefined;
    },
    clearConflicts(state) {
      state.conflictsScheduledMaintenance = undefined;
    },
    validateOneTimeScheduleData: (
      state,
      action: PayloadAction<IScheduledMaintenanceOneTimeInput>
    ) => {
      const error = validateOneTimeSchedule(action.payload);
      state.oneTimeError = error || null;
    },
    validateRecurringScheduleData: (
      state,
      action: PayloadAction<IScheduledMaintenanceRecurringInput>
    ) => {
      const error = validateRecurringSchedule(action.payload);
      state.recurringError = error || null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScheduledMaintenance.fulfilled, (state, action) => {
      const data = action.payload?.map((item) =>
        mapScheduledMaintenanceDtoToScheduledMaintenance(item)
      );
      state.existingScheduledMaintenance = data;
      state.loading = false;
    });
    builder.addCase(fetchScheduledMaintenance.pending, (state) => {
      state.existingScheduledMaintenance = [];
      state.loading = true;
    });
    builder.addCase(fetchScheduledMaintenance.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteScheduleMaintenance.fulfilled, (state, action) => {
      handleDeleteScheduleMaintenanceFulfilled(state, action.payload);
    });
    builder.addCase(deleteScheduleMaintenance.rejected, (state) => {
      state.deleteErrorMessage =
        "Failed to delete scheduled maintenance. Please try again later.";
    });

    builder.addCase(addScheduleMaintenance.fulfilled, (state, action) => {
      handleAddScheduleMaintenanceFulfilled(state, action.payload);
    });

    builder.addCase(addScheduleMaintenance.pending, (state) => {
      state.addScheduledMaintenanceError = undefined;
      state.conflictsScheduledMaintenance = undefined;
    });

    builder.addCase(addScheduleMaintenance.rejected, (state, action) =>
      handleAddScheduleMaintenanceRejected(state, action.payload)
    );
  },
});

const handleDeleteScheduleMaintenanceFulfilled = (
  state: ScheduleCommandState,
  scheduledId: string
) => {
  state.existingScheduledMaintenance =
    state.existingScheduledMaintenance.filter(
      (maintenance) => maintenance.id !== scheduledId
    );
};

const handleAddScheduleMaintenanceFulfilled = (
  state: ScheduleCommandState,
  scheduledMaintenance: IScheduledMaintenanceDto
) => {
  const mappedMaintenance =
    mapScheduledMaintenanceDtoToScheduledMaintenance(scheduledMaintenance);
  state.existingScheduledMaintenance = [
    ...state.existingScheduledMaintenance,
    mappedMaintenance,
  ];
};

const handleAddScheduleMaintenanceRejected = (
  state: ScheduleCommandState,
  payload?: {
    conflicts?: IScheduledMaintenanceOverlapResult[];
    message?: string;
  }
) => {
  if (payload?.conflicts) {
    state.conflictsScheduledMaintenance = payload.conflicts;
  } else if (payload?.message) {
    state.addScheduledMaintenanceError = payload.message;
  } else {
    state.addScheduledMaintenanceError =
      "Failed to add scheduled maintenance. Please try again.";
  }
};

const validateOneTimeSchedule = (
  data: IScheduledMaintenanceOneTimeInput
): string | null => {
  if (!data.startDateTimeUtc || !data.endDateTimeUtc) {
    return "Please select both start and end times.";
  }

  const start = dayjs(data.startDateTimeUtc);
  const end = dayjs(data.endDateTimeUtc);
  const oneYearFromNow = dayjs().add(1, "year");

  if (start.isAfter(end)) {
    return "End time must be after start time.";
  }

  if (end.isAfter(oneYearFromNow)) {
    return "Schedule must be within 1 year from now.";
  }

  return null;
};

const hasSelectedDayInDateRange = (
  recurringScheduleData: IScheduledMaintenanceRecurringInput
): boolean => {
  const { selectedDays, fromDateUtc, untilDateUtc } = recurringScheduleData;
  const startDate = dayjs(fromDateUtc);
  const endDate = dayjs(untilDateUtc);

  for (
    let date = startDate;
    date.isBefore(endDate) || date.isSame(endDate);
    date = date.add(1, "day")
  ) {
    if (selectedDays.includes(date.day())) {
      return true;
    }
  }
  return false;
};

const validateRecurringSchedule = (
  data: IScheduledMaintenanceRecurringInput
): string | null => {
  const {
    repeatEveryWeek,
    selectedDays,
    startTime,
    endTime,
    fromDateUtc,
    untilDateUtc,
  } = data;

  if (dayjs(fromDateUtc).isAfter(dayjs(untilDateUtc))) {
    return "End date must be after start date.";
  }

  if (!fromDateUtc) {
    return "Please select a start date.";
  }
  if (!untilDateUtc) {
    return "Please select an end date.";
  }
  if (!repeatEveryWeek) {
    return "Please select repeat frequency.";
  }
  if (!selectedDays || selectedDays.length === 0) {
    return "Please select at least one day.";
  }
  if (!startTime) {
    return "Please select a start time.";
  }
  if (!endTime) {
    return "Please select an end time.";
  }

  const oneYearAhead = dayjs().add(1, "year");
  if (
    dayjs(fromDateUtc).isAfter(oneYearAhead) ||
    dayjs(untilDateUtc).isAfter(oneYearAhead)
  ) {
    return "End time must be within 1 year from now.";
  }

  if (!hasSelectedDayInDateRange(data)) {
    return "Please make sure at least one day is in the scheduled range.";
  }

  return null;
};

export const fetchScheduledMaintenance = createAsyncThunk(
  "scheduleCommand/fetchScheduledMaintenance",
  async (siteId: string) => {
    return await getExistingScheduledMaintenance(siteId);
  }
);

export const deleteScheduleMaintenance = createAsyncThunk(
  "scheduleCommand/deleteScheduleMaintenance",
  async ({ siteId, scheduledId }: { siteId: string; scheduledId: string }) => {
    await deleteScheduledMaintenance(siteId, scheduledId);
    return scheduledId;
  }
);

export const addScheduleMaintenance = createAsyncThunk<
  IScheduledMaintenanceDto,
  { ianaTimeZone: string; scheduledData: IAddScheduledMaintenanceDto },
  {
    rejectValue: {
      conflicts?: IScheduledMaintenanceOverlapResult[];
      message?: string;
    };
  }
>(
  "scheduleCommand/addScheduleMaintenance",
  async ({ ianaTimeZone, scheduledData }, { rejectWithValue }) => {
    try {
      const response = await addScheduledMaintenance(
        ianaTimeZone,
        scheduledData
      );
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          return rejectWithValue({
            conflicts: error.response.data,
          });
        }
        return rejectWithValue({
          message:
            error.response?.data?.message ||
            "Failed to add scheduled maintenance. Please try again.",
        });
      }
      return rejectWithValue({
        message: "An unexpected error occurred. Please try again.",
      });
    }
  }
);

const mapScheduledMaintenanceDtoToScheduledMaintenance = (
  item: IScheduledMaintenanceDto
): IScheduledMaintenance => {
  return {
    ...item,
    scheduleType: item.recurrence
      ? ScheduledMaintenanceType.Recurring
      : ScheduledMaintenanceType.OneTime,
  };
};

export const {
  setOneTimeScheduleData,
  setRecurringScheduleData,
  setOneTimeError,
  setRecurringError,
  clearDeleteError,
  clearAddError,
  clearConflicts,
  validateOneTimeScheduleData,
  validateRecurringScheduleData,
} = ScheduleCommandSlice.actions;

export const scheduleCommandReducer = ScheduleCommandSlice.reducer;
