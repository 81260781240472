import { WebUserAppLifeCycle } from "./solarSightModels";

export type UnityResponseDelegate = (response:UnityResponse) => void;

/*
* Interface for the SolarSight API response. This is used internally for 2 way response communication.
 */
export interface iSolarSightStateProvider {
    getAppState() : WebUserAppLifeCycle;
    getLastResponse() : UnityResponse | null ;
    addListener(listener: UnityResponseDelegate) : void;
    removeListener(listener: UnityResponseDelegate) : void;
}

export class UnityResponse{
//Casing according to C#
    constructor(public Id:string,
                public Response:any|undefined,
                public ErrorMsg:string|undefined,
                public ErrorType:ResponseErrorType = ResponseErrorType.None)
    {
        if(Id === undefined || Id === null) {
            throw new Error("UnityResponse id is undefined");
        }
        if(ErrorMsg !== undefined && ErrorMsg !== null) {
            this.ErrorType = ResponseErrorType.Undefined;
        }
    }
}

export enum ResponseErrorType {
    // @ts-ignore
    Undefined     = undefined,
    None          = "None",
    Init          = "Init",
    PixelsServer  = "PixelsServer",
    FunctionCall  = "FunctionCall",
    InvalidArgs   = "InvalidArgs",
    NullArgs      = "NullArgs",
}