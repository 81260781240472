import { useSelector } from "react-redux";
import { RootState } from "../features/app/Store";
import { selectSiteId } from "../features/sites/SiteStore";
import CamerasPanel from "../features/cameras/CameraPanel";
import classes from "./Cameras.module.css";

const CamerasPage = () => {
  const siteId = useSelector(selectSiteId);
  const siteCameras = useSelector((state: RootState) => state.site.cameras);
  const panelClassName = classes[getPanelClassName(siteCameras.length)];

  return (
    <div className={classes["container"]}>
      <div className={classes["panels-container"]}>
        {siteCameras.map((camera) => (
          <div
            key={`${camera.id}_${camera.agentId}`}
            className={panelClassName}
          >
            <CamerasPanel siteId={siteId} cameraMetadata={camera} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CamerasPage;

const getPanelClassName = (panelsCount: number) => {
  if (panelsCount < 2) {
    return "";
  }
  if (panelsCount === 2) {
    return "panel-2-cols";
  }
  return "panel-3-cols";
};
