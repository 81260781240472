import {ISolarSightApi} from "./iSolarSightApi";
import {WebUserAppLifeCycle} from "./solarSightModels";
import {delayAsync, waitForAsync} from "./utils";
import {
    iSolarSightStateProvider,
    ResponseErrorType,
    UnityResponse,
    UnityResponseDelegate
} from "./iSolarSightStateProvider";
import {UnityInstance} from "../../unity/unityLoader";

const UnityObjectName: string = 'WebJsCallUnityService';

const AsyncCheckIntervalInMiliSec:number = 100;
const AsyncDefaultTimeoutMiliSec:number = 15_000;//To avoid lingearing async calls
const AsyncResponseTimeoutMiliSec:number = 100;//To avoid lingearing async calls

export class SolarSightApi implements ISolarSightApi {
    readonly #unityInstance: any;
    readonly #responseProvider: iSolarSightStateProvider;

    readonly #responseFunc: UnityResponseDelegate;
    readonly #onPaste:(e:ClipboardEvent)=>void;

    #response:UnityResponse | null = null;

    constructor(unityInstance:UnityInstance, ssState: iSolarSightStateProvider) {
        this.#unityInstance = unityInstance;
        this.#responseProvider = ssState;

        this.#responseFunc = this.onSolarSightEvent.bind(this);
        this.#responseProvider.addListener(this.#responseFunc);

        this.#onPaste = this.onPaste.bind(this);
        document.addEventListener('paste', this.#onPaste);
    }

    private onPaste = (event:ClipboardEvent) : void => {
        var pastedData = '';
        if (event.clipboardData && event.clipboardData.getData) {
            pastedData = event.clipboardData.getData('text');
        }
        //@ts-ignore // For IE
        else if (window.clipboardData && window.clipboardData.getData) {
            //@ts-ignore
            pastedData = window.clipboardData.getData('Text');
        }
        this.callUnity('OnClipboardPaste', pastedData);
    }

    public cleanup() {
        if(this.#responseProvider !== null) {
            this.#responseProvider.removeListener(this.#responseFunc);
        }
        document.removeEventListener('paste', this.#onPaste);
    }

    ///API functions
    public getAppState(): WebUserAppLifeCycle {
        return this.#responseProvider.getAppState();
    }

    public async setSiteAsync(siteId: string): Promise<void> {
        await this.waitForNotStateAsync(
            WebUserAppLifeCycle.NotReady,
            'ChangeSite',
            siteId);
    }

    public async setSelectedTrackersAsync(trackerIds: number[]): Promise<void> {
        await this.waitForStateAsync(
            WebUserAppLifeCycle.SiteLoaded,
            'ChangeSelectedTrackers',
            JSON.stringify(trackerIds));
    }

    public async refreshAsync(): Promise<void> {
        console.log(`ISolarSightApi.refreshAsync state=${this.getAppState()}`);
        await this.waitForStateAsync(
            WebUserAppLifeCycle.SiteLoaded,
            'Refresh');
    }

    public getVersionAsync = async () : Promise<string> => {
        return this.callUnityWithResponseAsync(
            "GetVersion");
    }

    private callUnityWithResponseAsync = async (
        command: string,
        args: string | undefined = undefined,
        timeoutMs: number = AsyncResponseTimeoutMiliSec
    ): Promise<any> => {
        this.#response = null;

        this.callUnity(command, args);

        if (this.#response === null) {
            await delayAsync(timeoutMs);
        }
        var response = this.#response as UnityResponse | null;
        if (response === null) {
            throw new Error(`Timeout waiting for ${command} response\n${JSON.stringify(response)}`);
        }
        if (response.Id !== command) {
            throw new Error(`Received response for command ${response.Id}, expected ${command}\n${JSON.stringify(response)}`);
        }
        if (response.ErrorType !== ResponseErrorType.None) {
            throw new Error(`${command} failed with errorType ${response.ErrorType}\n${JSON.stringify(response)}`);
        }
        return response.Response;
    }

    private onSolarSightEvent = (response:UnityResponse) : void => {
        console.debug(`SolarSightApi.onSolarSightEvent-response ${response.Id}`);
        this.#response = response;
    }

    private callUnity(funcName: string, args: string |undefined) {
        if (args !== undefined) {
            this.#unityInstance.SendMessage(UnityObjectName, funcName, args);
        }else {
            this.#unityInstance.SendMessage(UnityObjectName, funcName);
        }
    }

    private async waitForStateAsync(state: WebUserAppLifeCycle,
                                    messageFuncId:string | undefined = undefined,
                                    args:string |undefined = undefined,
                                        timeoutMilisec:number = AsyncDefaultTimeoutMiliSec) : Promise<void>{
        await this.waitForAsync(
            () => this.getAppState() === state,
            messageFuncId,
            args,
            timeoutMilisec);
    }

    private async waitForNotStateAsync(state: WebUserAppLifeCycle,
                                    messageFuncId:string | undefined = undefined,
                                    args:string |undefined = undefined,
                                    timeoutMilisec:number = AsyncDefaultTimeoutMiliSec) : Promise<void>{
        await this.waitForAsync(
            () => this.getAppState() !== state,
            messageFuncId,
            args,
            timeoutMilisec);
    }

    private async waitForAsync(getBoolFunction: () => boolean,
                                    messageFuncId:string | undefined,
                                    args:string |undefined,
                                    timeoutMilisec:number) : Promise<void>{
        await waitForAsync(
            getBoolFunction,
            AsyncCheckIntervalInMiliSec,
            timeoutMilisec);
        if (messageFuncId !== undefined) {
            console.debug(`${messageFuncId}`);
            this.callUnity(messageFuncId, args);
        }
    }
}