import axios from "axios";
import APP_CONFIG from "../app/configuration/AppConfig";
import { ISitesFeatureFlagsDictionary } from "./FeatureFlagsModels";

const getMultiSiteFeatureFlagsAsync = async (
  siteIds: string[]
): Promise<ISitesFeatureFlagsDictionary> => {
  const url = APP_CONFIG.serviceUrls.apiHostUrl + APP_CONFIG.apiFeatureFlags;
  const httpResponse = await axios.post<ISitesFeatureFlagsDictionary>(
    url,
    siteIds
  );
  return httpResponse.data;
};

export default getMultiSiteFeatureFlagsAsync;
