import OneTimeSchedule from "./OneTimeSchedule";
import RecurringSchedule from "./RecurringSchedule";
import { ITab } from "../../../SolarGikLib/tabs/TabsModels";
import { ScheduledMaintenanceType } from "./Models";

const scheduleCommandTabs: ITab[] = [
  {
    label: "One Time Schedule",
    isSingleItem: false,
    key: ScheduledMaintenanceType.OneTime,
    content: <OneTimeSchedule />,
  },
  {
    label: "Recurring Schedule",
    isSingleItem: false,
    key: ScheduledMaintenanceType.Recurring,
    content: <RecurringSchedule />,
  },
];
export default scheduleCommandTabs;
