import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHA256, enc as Encoding } from "crypto-js";
import classes from "./CardSiteStyledLineChartWrapper.module.css";
import { getChartType } from "./Utils";
import { failSafeInit, repetitivePolling } from "../../common/AsyncUtils";
import Card from "../../SolarGikLib/cards/Card";
import SolarGikStyledChart from "../../SolarGikLib/SolarGikStyledChart";
import APP_CONFIG from "../app/configuration/AppConfig";
import { intervalStringToSeconds } from "../app/Formating";
import { AppDispatch, RootState } from "../app/Store";
import {
  IChartTagConfig,
  IFetchTagsParams,
} from "../data_point/charts/ChartModel";
import { createLines } from "../data_point/charts/ChartTagsUtils";
import ExportHistoryTagsToCsvFile from "../data_point/ExportHistoryTagsToCsvFile";
import {
  IChartProps,
  IChartSingleLineConfig,
  IHistoryTagsParams,
} from "../data_point/models/TagChartModel";
import { IExtendedLineChartInfo } from "../data_point/models/TagsModels";
import { fetchTags } from "../app/store/TagsChartStore";
import { invalidTagMetadata } from "../app/TagsMetadataUtils";
import ChartCardSkeleton from "./ChartCardSkeleton";

const initLinesConfig: IChartSingleLineConfig[] = [];

const hash = (input: string) => SHA256(input).toString(Encoding.Hex);

const CardSiteStyledLineChartWrapper: FC<IChartTagConfig> = ({
  siteId,
  title,
  tagInfo,
  samplingInterval,
  timeRange,
  chartHeight,
  isRepetitiveRequest,
  isExportable,
  overrideShowLegend,
  useTagMetadataForMinMax = true,
  showChartTitle: isShowChartTitle = false,
}) => {
  const tagsIds = tagInfo.map((tag) => tag.id).join(",");
  const key = `${title}_${hash(siteId + tagsIds)}`;

  const dispatch = useDispatch<AppDispatch>();
  const multiSitesMetadata = useSelector(
    (state: RootState) => state.multiSitesMetadata.sites
  );
  const tagMetadata = multiSitesMetadata[siteId].tags;
  const [linesConfig, setLinesConfig] = useState(initLinesConfig);
  const [isDataExists, setIsDataExists] = useState(false);

  const tagsIdArrFunc = () =>
    tagInfo.map((tag) => {
      return tag.id;
    });
  const [tagsIdArr, setTagsIdArr] = useState<string[]>(tagsIdArrFunc());
  const newTagsArr = tagsIdArrFunc();
  if (
    tagsIdArr.length != newTagsArr.length ||
    tagsIdArr.some((v, i) => v !== newTagsArr[i])
  ) {
    setTagsIdArr(newTagsArr);
  }

  const chartData = useSelector((state: RootState) => {
    const siteData = state.multiSitesChartsData[siteId];
    return siteData ? siteData[key] : null;
  });

  const [tagWithGraphInfo, setTagWithGraphInfo] = useState<
    IExtendedLineChartInfo[]
  >([]);
  const [rangeTimeInZoneTimeState, setRangeTimeInZoneTimeState] =
    useState(timeRange);
  if (
    rangeTimeInZoneTimeState.start !== timeRange.start ||
    rangeTimeInZoneTimeState.end !== timeRange.end
  ) {
    setRangeTimeInZoneTimeState(timeRange);
  }
  useEffect(() => {
    if (tagMetadata === null || tagMetadata === undefined) {
      return;
    } else {
      const updatedTagWithGraphInfo = tagInfo.map((tag) => {
        const metadata =
          multiSitesMetadata[siteId]?.tags[tag.id] ?? invalidTagMetadata;
        return {
          id: tag.id,
          isCombinedYAxis: tag.isCombinedYAxis,
          displayName: tag.displayName ?? metadata?.displayName ?? "",
          min: useTagMetadataForMinMax ? metadata.low : undefined,
          max: useTagMetadataForMinMax ? metadata.high : undefined,
        };
      });

      setTagWithGraphInfo(updatedTagWithGraphInfo);
    }
  }, [tagMetadata, chartData]);
  const intervalInSeconds = intervalStringToSeconds(samplingInterval);
  const paramsForGraphDisplayRequest: IHistoryTagsParams = {
    siteId: siteId,
    tags: tagsIdArr,
    range: {
      start: timeRange.start,
      end: timeRange.end,
    },
    samplingIntervalInSeconds: intervalInSeconds,
  };

  const paramsForExport: IHistoryTagsParams = {
    siteId: siteId,
    tags: tagsIdArr,
    range: {
      start: timeRange.start,
      end: timeRange.end,
    },
    samplingIntervalInSeconds:
      tagsIdArr.length > 0 && tagsIdArr[0].includes("CurrentElevation")
        ? APP_CONFIG.trackersExportIntervalInSeconds
        : APP_CONFIG.defaultExportIntervalInSeconds,
  };
  const chartType = getChartType(
    rangeTimeInZoneTimeState.start,
    rangeTimeInZoneTimeState.end
  );
  const chartConfig: IChartProps = {
    title: title,
    timeRange: timeRange,
    samplingIntervalInSeconds: intervalInSeconds,
    chartHeight: chartHeight,
    tagInfo: tagInfo,
    overrideShowLegend,
    chartType: chartType,
    showChartTitle: isShowChartTitle,
  };

  const fetchTagsApi = async (signal: AbortSignal) => {
    if (signal.aborted) {
      return;
    }

    //action get chart tags
    const params: IFetchTagsParams = {
      interval: intervalInSeconds,
      paramsForGraphDisplayRequest: paramsForGraphDisplayRequest,
      key: key,
    };
    dispatch(fetchTags(params));
  };

  useEffect(() => {
    if (tagInfo.length === 0) {
      return;
    }

    const cntrlr = new AbortController();
    setIsDataExists(false);
    if (siteId) {
      if (isRepetitiveRequest) {
        repetitivePolling(
          "fetchTagsApi",
          fetchTagsApi,
          APP_CONFIG.milliSecBetweenGetHistory,
          APP_CONFIG.millisecondsBetweenChartHistoryFailedRequest,
          cntrlr.signal
        );
      } else {
        failSafeInit(
          "fetchTagsApi",
          fetchTagsApi,
          APP_CONFIG.millisecondsBetweenChartHistoryFailedRequest,
          cntrlr.signal
        );
      }
    }
    return () => {
      cntrlr.abort();
    };
  }, [siteId, rangeTimeInZoneTimeState, tagsIdArr, isRepetitiveRequest]);

  useEffect(() => {
    if (!chartData || tagWithGraphInfo.length === 0) {
      return;
    }
    if (Object.keys(chartData.tagToValues).length !== tagWithGraphInfo.length) {
      return;
    }
    const currentLineConfig: IChartSingleLineConfig[] = createLines(
      chartData.tagToValues,
      tagWithGraphInfo
    );

    setLinesConfig(currentLineConfig);
    setIsDataExists(true);
  }, [chartData, tagWithGraphInfo]);

  return (
    <Card
      actions={
        isExportable && (
          <span className={classes["chart-header"]}>
            <ExportHistoryTagsToCsvFile
              params={paramsForExport}
              chartTitle={chartConfig.title}
            />
          </span>
        )
      }
      title={title}
      contentStyle={{
        marginBottom: "0px",
      }}
    >
      <div className={classes.chart}>
        <ChartCardSkeleton isDataExist={isDataExists}>
          <SolarGikStyledChart
            chartConfig={chartConfig}
            linesConfig={linesConfig}
          />
        </ChartCardSkeleton>
      </div>
    </Card>
  );
};
export default CardSiteStyledLineChartWrapper;
