export class DateTimeFormatter {
  private readonly dateTimeFormat: Intl.DateTimeFormatOptions;
  private readonly dateTimeFormatWithSeconds: Intl.DateTimeFormatOptions;
  private readonly dateFormat: Intl.DateTimeFormatOptions;
  private readonly timeFormat: Intl.DateTimeFormatOptions;

  public constructor(private readonly timeZone: string) {
    this.dateTimeFormat = {
      timeZone: timeZone,
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    this.dateTimeFormatWithSeconds = {
      timeZone: timeZone,
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    this.dateFormat = {
      timeZone: timeZone,
      year: "numeric",
      month: "short",
      day: "2-digit",
    };

    this.timeFormat = {
      timeZone: timeZone,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };
  }

  public formatDateAndTime(value: number | Date, includeSeconds = false) {
    const format = includeSeconds
      ? this.dateTimeFormatWithSeconds
      : this.dateTimeFormat;
    return this.formatImpl(value, format).replaceAll(",", "");
  }

  public formatDate(value: number | Date) {
    return this.formatImpl(value, this.dateFormat).replaceAll(",", "");
  }

  public formatTime(value: number | Date) {
    return this.formatImpl(value, this.timeFormat).replaceAll(",", "");
  }

  public format(value: number | Date, format: Intl.DateTimeFormatOptions) {
    return this.formatImpl(value, { ...format, timeZone: this.timeZone });
  }

  private formatImpl(value: number | Date, format: Intl.DateTimeFormatOptions) {
    const valueAsDate = typeof value === "number" ? new Date(value) : value;
    return valueAsDate.toLocaleString("en-GB", format);
  }
}

export default DateTimeFormatter;
