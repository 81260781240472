import axios from "axios";
import APP_CONFIG from "../app/configuration/AppConfig";

export const getIsDiffuseOptimizationOnAsync = async (
  siteId: string
): Promise<boolean> => {
  const response = await axios.get(
    `${APP_CONFIG.serviceUrls.apiHostUrl}/diffuseOptimization/${siteId}/status`
  );
  return response.data;
};
