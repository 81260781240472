import { useEffect, useState } from "react";
import APP_CONFIG from "../../features/app/configuration/AppConfig";

export const useLoaderTimeout = (isDataExists: boolean) => {
  const [isLoading, setIsLoading] = useState(!isDataExists);
  useEffect(() => {
    if (!isDataExists) {
      setIsLoading(true);
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, APP_CONFIG.milliSecLoaderTimeOut);
      return () => {
        clearTimeout(timeoutId);
      };
    }
    if (isDataExists) {
      setIsLoading(false);
    }
  }, [isDataExists]);
  return isLoading;
};
