import { FC } from "react";
import { humanizeEnumValue } from "../../common/EnumUtils";
import classes from "./BatteryStatus.module.css";
import { ChargingStateEnum } from "./TrackersModels";
import TrackerBatteryIcon from "./TrackerBatteryIcon";
import ChargingBatteryIcon from "./ChargingBatteryIcon";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
interface BatteryStatusProps {
  chargePercentage?: number;
  chargingState?: number;
}
const BatteryStatus: FC<BatteryStatusProps> = ({
  chargePercentage,
  chargingState,
}) => {
  const isCharging = chargingState === ChargingStateEnum.Charging;

  return (
    <div className={classes.row}>
      <div className={classes["charging-state"]}>
        {chargingState == undefined
          ? NO_VALUE_PLACEHOLDER
          : humanizeEnumValue(ChargingStateEnum[chargingState]) ??
            NO_VALUE_PLACEHOLDER}
      </div>
      <div className={classes["right-border"]}></div>
      <div className={classes["charging-percent"]}>
        {chargePercentage == null
          ? NO_VALUE_PLACEHOLDER
          : chargePercentage + "%"}
        {!isCharging && (
          <TrackerBatteryIcon chargePercentage={chargePercentage} />
        )}
        {isCharging && (
          <ChargingBatteryIcon chargePercentage={chargePercentage} />
        )}
      </div>
    </div>
  );
};
export default BatteryStatus;
