import axios from "axios";

import APP_CONFIG from "../app/configuration/AppConfig";
import { ICameraId } from "./Models";

export const fetchSnapshots = async (
  siteId: string,
  camera: ICameraId,
  signal: AbortSignal
): Promise<string> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/cameras/site/${siteId}/${camera.agentId}/${camera.id}/snapshot`;
  const httpResponse = await axios.get(url, {
    signal,
    responseType: "blob",
  });
  const reader = new window.FileReader();
  return new Promise<string>((resolve, reject) => {
    reader.readAsDataURL(httpResponse.data);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(new Error("Failed to load image"));
  });
};

export const moveCamera = async (
  siteId: string,
  camera: ICameraId,
  x: number,
  y: number,
  signal: AbortSignal
): Promise<void> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/cameras/site/${siteId}/${camera.agentId}/${camera.id}/move`;
  const args = { x, y, milliseconds: 1000 };
  await axios.post<void>(url, args, { signal });
};

export const healthCheck = async (
  siteId: string,
  camera: ICameraId,
  signal: AbortSignal
): Promise<{ error?: string }> => {
  const url = `${APP_CONFIG.serviceUrls.apiHostUrl}/cameras/site/${siteId}/${camera.agentId}/${camera.id}/health`;
  const response = await axios.get<{ detail?: string }>(url, {
    signal,
    validateStatus: () => true,
  });
  return {
    error:
      response.status == 200
        ? undefined
        : response.data?.detail ?? "unknown error",
  };
};
