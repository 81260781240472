import { GridColDef } from "@mui/x-data-grid";
import DateTimeFormatter from "../../../app/DateTimeFormatter";
import { TrackerNamesMap } from "../../../sites/SiteModels";
import {
  AlertTransition,
  ComponentType,
  IAlertMetadata,
  ISingleDeviceAlertTransition,
  UrgencyLevel,
} from "../../DTOs";
import { AlertUnion } from "../../Models";
import {
  createAlertDescriptionTokenFormatter,
  applyAlertDescriptionTextFormatter,
  fractionToPercentage,
} from "../../Utils";
import {
  createDescriptionFormatter,
  DescriptionCell,
} from "../tableComponent/DescriptionCell";
import { NormalizedAggregatedAlertTransition } from "./Models";
import LightTooltipOnEllipsis from "../../../../SolarGikLib/tooltip/LightTooltipOnEllipsis";
import classes from "./TableColumns.module.css";
import { tryGetTrackerName } from "../../../trackers/TrackerName";
import trackerNameClasses from "../../../trackers/TrackerName.module.css";
import {
  createFormattedCell,
  createTimeCell,
} from "../../../../common/table/CellFactory";

export function getTableColumns(
  alert: AlertUnion,
  metadata: IAlertMetadata,
  formatter: DateTimeFormatter,
  trackerNamesMap: TrackerNamesMap,
  isSmallScreen: boolean
): GridColDef<AlertTransition>[] {
  const res: GridColDef<AlertTransition>[] = [
    {
      field: "event",
      headerName: "Event",
      valueGetter: (params) => {
        const transition = params.row;
        if (transition.newUrgencyLevel == transition.oldUrgencyLevel) {
          return "Updated";
        }
        if (transition.newUrgencyLevel == UrgencyLevel.Closed) {
          return "Resolved";
        }
        if (transition.oldUrgencyLevel == UrgencyLevel.Closed) {
          return "Opened";
        }
        if (transition.newUrgencyLevel > transition.oldUrgencyLevel) {
          return "Escalated";
        }
        return "De-escalated";
      },
      width: 100,
    },
    {
      field: "urgencyLevel",
      headerName: "Urgency",
      ...createFormattedCell((row) => {
        const { newUrgencyLevel: value } = row;
        const formatted =
          value === UrgencyLevel.Closed ? "--" : UrgencyLevel[value];
        return { value, formatted };
      }),
      width: isSmallScreen ? 90 : 110,
    },
    {
      ...createTimeCell((row) => row.transitionTime, formatter, isSmallScreen),
      field: "transitionTime",
      headerName: "Time",
    },
    {
      field: "info",
      headerName: "Info",
      valueGetter: (params) => {
        if (params.row.newUrgencyLevel === undefined) {
          return "";
        }
        if (params.row.newUrgencyLevel === UrgencyLevel.Closed) {
          const formatter = createAlertDescriptionTokenFormatter(
            alert,
            metadata,
            trackerNamesMap
          );
          return applyAlertDescriptionTextFormatter(
            metadata.closedStateDescription,
            formatter
          );
        }
        if (alert.kind === "aggregated") {
          const transition = params.row as NormalizedAggregatedAlertTransition;
          return applyAlertDescriptionTextFormatter(
            metadata.openStateDescription,
            {
              Total: () => transition.allDevices.length,
              Percent: () => fractionToPercentage(transition.errorFraction),
            }
          );
        }
        if (alert.kind === "single-device") {
          const transition = params.row as ISingleDeviceAlertTransition;
          const alertWithUpdatedAdditionalInfo = {
            ...alert,
            additionalInfo: transition.additionalInfo,
          };
          const formatter = createAlertDescriptionTokenFormatter(
            alertWithUpdatedAdditionalInfo,
            metadata,
            trackerNamesMap
          );
          return applyAlertDescriptionTextFormatter(
            metadata.openStateDescription,
            formatter
          );
        }
        return "N/A";
      },
      renderCell: (params) => {
        if (params.row.newUrgencyLevel === undefined) {
          return <></>;
        }
        if (params.row.newUrgencyLevel == UrgencyLevel.Closed) {
          const formatter = createDescriptionFormatter(alert, metadata);
          return (
            <DescriptionCell
              rawDescription={metadata.closedStateDescription}
              formatter={formatter}
            />
          );
        }
        if (alert.kind === "aggregated") {
          const transition = params.row as NormalizedAggregatedAlertTransition;
          return (
            <DescriptionCell
              rawDescription={metadata.openStateDescription}
              formatter={{
                Total: (index) => (
                  <span key={index}>{transition.allDevices.length}</span>
                ),
                Percent: (index) => (
                  <span key={index}>
                    {fractionToPercentage(transition.errorFraction)}
                  </span>
                ),
              }}
            />
          );
        }
        if (alert.kind === "single-device") {
          const transition = params.row as ISingleDeviceAlertTransition;
          const alertWithUpdatedAdditionalInfo = {
            ...alert,
            additionalInfo: transition.additionalInfo,
          };
          const formatter = createDescriptionFormatter(
            alertWithUpdatedAdditionalInfo,
            metadata
          );
          return (
            <DescriptionCell
              rawDescription={metadata.openStateDescription}
              formatter={formatter}
            />
          );
        }
        return <span>N/A</span>;
      },
      flex: 0.3,
    },
  ];
  if (alert.kind === "aggregated") {
    res.push({
      field: "deviceChanges",
      headerName: "Device changes",
      valueGetter: (params) => {
        const transition = params.row as NormalizedAggregatedAlertTransition;
        if (transition.allDevices === undefined) {
          return;
        }
        const componentType = metadata.componentType;
        if (componentType === ComponentType.System) {
          return "";
        }
        const allDevicesIncludingDeleted = transition.allDevices
          .concat(transition.removedDeviceIds)
          .sort((a, b) => a - b);
        if (componentType === ComponentType.Tracker) {
          return allDevicesIncludingDeleted
            .map((x) => tryGetTrackerName(x, trackerNamesMap))
            .join(", ");
        }
        return allDevicesIncludingDeleted.join(", ");
      },
      renderCell: (params) => {
        const transition = params.row as NormalizedAggregatedAlertTransition;
        if (transition.allDevices === undefined) {
          return;
        }
        const isTrackerComponent =
          metadata.componentType === ComponentType.Tracker;
        const extraClassName = isTrackerComponent
          ? trackerNameClasses["tracker-name"]
          : "";
        const allDevicesIncludingDeleted = transition.allDevices
          .concat(transition.removedDeviceIds)
          .sort((a, b) => a - b);
        const spans = allDevicesIncludingDeleted.map((x, index) => {
          const deviceName = isTrackerComponent
            ? tryGetTrackerName(x, trackerNamesMap)
            : x;
          const isAdded = transition.addedDeviceIds.includes(x);
          const isRemoved = transition.removedDeviceIds.includes(x);
          const containerClassName =
            (isAdded ? classes["added-device"] : "") +
            (isRemoved ? classes["removed-device"] : "");
          return (
            <>
              {index > 0 && ","}
              <span className={containerClassName}>
                {(isAdded || isRemoved) && (
                  <span className={classes["device-prefix"]}>
                    {isAdded ? "+" : "-"}
                  </span>
                )}
                <span
                  key={x}
                  className={extraClassName + " " + classes["device"]}
                >
                  {deviceName}
                </span>
              </span>
            </>
          );
        });
        return (
          <LightTooltipOnEllipsis
            title={<div>{spans}</div>}
            customTooltipSx={{ padding: "1.2em", fontSize: 14 }}
          >
            <div className={classes["component-ids-column"]}>
              <span className={extraClassName}>{spans}</span>
            </div>
          </LightTooltipOnEllipsis>
        );
      },
      width: isSmallScreen ? 150 : 180,
    });
  }
  return res;
}
