import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import classes from "./PixelsView.module.css";
import { RootState } from "../../app/Store";
import {
  initializePixelsCanvas,
  solarSightProvider,
} from "./pixelsClientWrapper";
import { protectedResources } from "../../auth/authConfig";
import { acquireAccessToken } from "../../auth/authUtils";

interface IPixelViewProps {
  siteId: string;
}

export const PixelsView = ({ siteId }: IPixelViewProps) => {
  solarSightProvider.trackersStatus = useSelector(
    (state: RootState) => state.multiSitesTrackers.trackersStatus[siteId] || []
  );

  const [isError, setIsError] = useState<boolean>();

  const { instance } = useMsal();

  const fetchAccessToken = async () => {
    const scope = protectedResources.webAPI.scopes;
    const token = await acquireAccessToken(instance, scope);
    solarSightProvider.userToken = token;
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchAccessToken();
    const intervalId = setInterval(async () => {
      await fetchAccessToken();
    }, 60 * 1000);
    (async () => {
      try {
        await initializePixelsCanvas(siteId, abortController.signal);
      } catch (error) {
        setIsError(true);
      }
    })();
    return () => {
      abortController.abort()
      clearInterval(intervalId)
    };
  }, []);

  return isError ? (
    <div className={`${classes.container} ${classes['error-container']}`}>
      <div className={classes['error-content']}>
        <div className={classes['error-heading']}>
          {"Oops!\n"}
        </div>
        {"Something Went Wrong.\nPlease Try Again"}
      </div>
    </div>
  ) :
    <canvas className={`${classes.container} ${classes['unity-canvas']}`} id="solar-sight-canvas" />;
};
