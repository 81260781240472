import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import {
  ArrowDownward,
  ArrowUpward,
  Download,
  Pause,
  PlayArrow,
  TurnLeft,
  TurnRight,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { fetchSnapshot, getKey, healthCheck, moveCamera } from "./CamerasStore";
import { AppDispatch, RootState } from "../app/Store";
import { LoadingState } from "../app/LoadingState";
import { ICameraMetadata } from "../sites/SiteModels";
import classes from "./CameraPanel.module.css";
import SolarGikAlert from "../../SolarGikLib/alerts/Alert";
import getIcons from "../../SolarGikLib/icons/Icons";
import {
  GeneralIcons,
  IconCategory,
} from "../../SolarGikLib/icons/IconsModels";
import { DAYJS_YEAR_TO_MINUTE_SHORT_FORMAT } from "../app/DayjsUtils";
import APP_CONFIG from "../app/configuration/AppConfig";

interface ICameraPanelProps {
  siteId: string;
  cameraMetadata: ICameraMetadata;
}

const autoPauseAfterMs = 5 * 60 * 1000;

const CamerasPanel: FC<ICameraPanelProps> = ({ siteId, cameraMetadata }) => {
  const dispatch = useDispatch<AppDispatch>();
  const cameraId = getKey(cameraMetadata);
  const snapshotState = useSelector(
    (state: RootState) => state.cameras.snapshots[cameraId]
  );
  const movementState = useSelector(
    (state: RootState) => state.cameras.movements[cameraId]
  );
  const [isLiveStreamOn, setIsLiveStreamOn] = useState(true);
  const [isMouseHover, setIsMouseHover] = useState(false);
  const SolarGikLogoIcon = getIcons(
    GeneralIcons.SolarGikLogo,
    IconCategory.General
  );
  const healthState = usePeriodicHealthCheck(dispatch, siteId, cameraMetadata);
  usePeriodicSnapshot(
    dispatch,
    !healthState?.Error && isLiveStreamOn,
    siteId,
    cameraMetadata
  );
  useAutoPause(isLiveStreamOn, setIsLiveStreamOn);
  const move = (x: number, y: number) =>
    dispatch(moveCamera({ siteId, cameraId: cameraMetadata, x, y }));
  const toggleLiveStream = () => setIsLiveStreamOn(!isLiveStreamOn);
  const movementAllowed =
    !healthState?.Error && movementState?.CommandState !== LoadingState.Pending;
  const alertComponent = healthState.Error && (
    <div className={classes["error-banner"]}>
      <SolarGikAlert
        message={{
          text: healthState.Error.toString(),
          severity: "error",
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setMessage={() => {}}
        isCloseIconVisible={false}
      />
    </div>
  );

  return (
    <div
      className={classes["container"]}
      onMouseEnter={() => setIsMouseHover(true)}
      onMouseLeave={() => setIsMouseHover(false)}
    >
      {!snapshotState?.Image && (
        <div className={classes["image-container-placeholder"]}>
          {alertComponent}
          {healthState?.Error ? (
            <div className={classes["offline-banner"]}>OFFLINE</div>
          ) : (
            <SolarGikLogoIcon className={classes["logo"]} />
          )}
          <div className={classes["camera-name"]}>{cameraMetadata.name}</div>
        </div>
      )}
      {snapshotState?.Image && (
        <div className={classes["image-container"]}>
          {alertComponent}
          {healthState?.Error && (
            <div className={classes["offline-banner"]}>OFFLINE</div>
          )}
          <div className={classes["camera-name"]}>{cameraMetadata.name}</div>
          <img
            className={classes["image"]}
            src={snapshotState?.Image}
            alt="Camera's image"
          />
          {isLiveStreamOn && (
            <div
              className={
                classes["buttons-container"] +
                " " +
                classes["movement-buttons"] +
                " " +
                (isMouseHover ? classes["container-mouse-hover"] : "")
              }
            >
              <div>
                <IconButton
                  disabled={!movementAllowed}
                  onClick={() => move(0, 1)}
                  size="small"
                >
                  <ArrowUpward />
                </IconButton>
              </div>
              <div>
                <IconButton
                  disabled={!movementAllowed}
                  onClick={() => move(1, 0)}
                  size="small"
                >
                  <TurnLeft />
                </IconButton>
                <div className={classes["spacer"]}></div>
                <IconButton
                  disabled={!movementAllowed}
                  onClick={() => move(-1, 0)}
                  size="small"
                >
                  <TurnRight />
                </IconButton>
              </div>
              <div>
                <IconButton
                  disabled={!movementAllowed}
                  onClick={() => move(0, -1)}
                  size="small"
                >
                  <ArrowDownward />
                </IconButton>
              </div>
            </div>
          )}
          <div
            className={
              classes["buttons-container"] +
              " " +
              classes["buttons-container-bottom"] +
              " " +
              (isMouseHover ? classes["container-mouse-hover"] : "")
            }
          >
            <IconButton
              onClick={() =>
                downloadImage(cameraMetadata.name, snapshotState?.Image)
              }
              size="small"
            >
              <Download />
            </IconButton>
          </div>
          <div
            className={
              classes["center-play-button"] +
              " " +
              (isMouseHover ? classes["container-mouse-hover"] : "") +
              " " +
              (!isLiveStreamOn ? classes["paused"] : "")
            }
          >
            <IconButton onClick={toggleLiveStream} size="large">
              {isLiveStreamOn && <Pause />}
              {!isLiveStreamOn && <PlayArrow />}
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

function usePeriodicSnapshot(
  dispatch: AppDispatch,
  active: boolean,
  siteId: string,
  cameraMetadata: ICameraMetadata
) {
  const [isInitialSnapshot, setIsInitialSnapshot] = useState(true);
  useEffect(() => {
    if (!active) {
      return;
    }
    let dispatchOp = dispatch(
      fetchSnapshot({
        siteId,
        cameraId: cameraMetadata,
        initial: isInitialSnapshot,
      })
    );
    setIsInitialSnapshot(false);
    const interval = setInterval(() => {
      dispatchOp.abort();
      dispatchOp = dispatch(
        fetchSnapshot({ siteId, cameraId: cameraMetadata, initial: false })
      );
    }, APP_CONFIG.milliSecBetweenCamerasSnapshot);
    return () => {
      clearInterval(interval);
      dispatchOp.abort();
    };
  }, [active]);
}

const initialPeriodicHealthCheckInnerState: {
  CommandState: LoadingState;
  Error?: string;
} = {
  CommandState: LoadingState.Pending,
};

function usePeriodicHealthCheck(
  dispatch: AppDispatch,
  siteId: string,
  cameraMetadata: ICameraMetadata
) {
  const cameraId = getKey(cameraMetadata);
  const healthState = useSelector(
    (state: RootState) => state.cameras.healthCheck[cameraId]
  );
  const [innerState, setInnerState] = useState(initialPeriodicHealthCheckInnerState);
  useEffect(() => {
    let dispatchOp = dispatch(
      healthCheck({ siteId, cameraId: cameraMetadata })
    );
    const interval = setInterval(() => {
      dispatchOp.abort();
      dispatchOp = dispatch(healthCheck({ siteId, cameraId: cameraMetadata }));
    }, APP_CONFIG.milliSecBetweenCamerasHealthCheck);
    return () => {
      clearInterval(interval);
      dispatchOp.abort();
    };
  }, []);
  if (
    healthState &&
    healthState.CommandState !== LoadingState.Pending &&
    (healthState.CommandState !== innerState.CommandState ||
      healthState.Error !== innerState.Error)
  ) {
    setInnerState(healthState);
  }
  return innerState;
}

function useAutoPause(
  isLiveStreamOn: boolean,
  setIsLiveStreamOn: (newState: boolean) => void
) {
  useEffect(() => {
    const timeout = setTimeout(
      () => setIsLiveStreamOn(false),
      autoPauseAfterMs
    );
    return () => clearTimeout(timeout);
  }, [isLiveStreamOn]);
}

function downloadImage(cameraName: string, image?: string) {
  if (!image) {
    return;
  }
  const url = `data:${image}`;
  const link = document.createElement("a");
  link.href = url;
  const fileNameSafeCameraName = cameraName
    .replace(/[^a-z0-9]/gi, "_")
    .toLowerCase();
  const fileNameTime = dayjs().format(DAYJS_YEAR_TO_MINUTE_SHORT_FORMAT);
  const fileName = `snapshot_${fileNameSafeCameraName}_${fileNameTime}.jpg`;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}

export default CamerasPanel;
