import { FC } from "react";
import dayjs from "dayjs";
import { IScheduledMaintenance } from "./Models";
import classes from "./CellScheduledMaintenanceTime.module.css";
import {
  DAYJS_YEAR_TO_DAY_SHORT_FORMAT,
  DAYJS_YEAR_TO_MINUTE_SHORT_FORMAT,
} from "../../app/DayjsUtils";
import { TextEnum } from "../../../SolarGikLib/TextStyles";

const formatTime = (time: string) => dayjs(time, "HH:mm:ss").format("HH:mm");

interface ICellScheduledMaintenanceTimeProps {
  value: IScheduledMaintenance;
  siteTimezone: string;
}
const CellScheduledMaintenanceTime: FC<ICellScheduledMaintenanceTimeProps> = ({
  value,
  siteTimezone,
}) => {
  const startTime = dayjs(value.startTimeUtc).tz(siteTimezone);
  const endTime = dayjs(value.endTimeUtc).tz(siteTimezone);
  if (value.recurrence) {
    return (
      <div className={`${classes["time-cell"]} ${TextEnum.h6}`}>
        <div className={classes["time-cell-date"]}>
          <span>{startTime.format(DAYJS_YEAR_TO_DAY_SHORT_FORMAT)}</span>
          <span> - </span>
          <span>{endTime.format(DAYJS_YEAR_TO_DAY_SHORT_FORMAT)}</span>
        </div>
        <div className={classes["time-cell-time"]}>
          <span>{formatTime(value.recurrence.startTime)}</span>
          <span> - </span>
          <span>{formatTime(value.recurrence.endTime)}</span>
        </div>
      </div>
    );
  }
  return (
    <div className={`${classes["time-cell"]} ${TextEnum.h6}`}>
      <div>
        <span>{startTime.format(DAYJS_YEAR_TO_MINUTE_SHORT_FORMAT)}</span>
      </div>
      <div>
        <span> - </span>
      </div>
      <div>
        <span>{endTime.format(DAYJS_YEAR_TO_MINUTE_SHORT_FORMAT)}</span>
      </div>
    </div>
  );
};
export default CellScheduledMaintenanceTime;
