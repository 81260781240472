import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { resetSiteSpecificData } from "../app/store/GlobalActions";
import * as Api from "./API";
import { ICameraId } from "./Models";
import { LoadingState } from "../app/LoadingState";

export const getKey = (cameraId: ICameraId) =>
  `${cameraId.agentId}-${cameraId.id}`;

interface ICamerasStoreState {
  healthCheck: {
    [key: string]: {
      CommandState: LoadingState;
      Error?: string;
    };
  };
  snapshots: {
    [key: string]: {
      Image: string;
      SnapshotTime?: Date;
      CommandState: LoadingState;
    };
  };
  movements: { [key: string]: { CommandState: LoadingState } };
}

export const defaultProperties: ICamerasStoreState = {
  healthCheck: {},
  snapshots: {},
  movements: {},
};

export const camerasSlice = createSlice({
  name: "cameras",
  initialState: defaultProperties,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetSiteSpecificData, () => {
        return defaultProperties;
      })
      .addCase(healthCheck.pending, (state, action) => {
        const cameraId = getKey(action.meta.arg.cameraId);
        state.healthCheck[cameraId] = {
          CommandState: LoadingState.Pending,
        };
      })
      .addCase(healthCheck.fulfilled, (state, action) => {
        const cameraId = getKey(action.meta.arg.cameraId);
        state.healthCheck[cameraId] = {
          CommandState:
            action.payload.error == null
              ? LoadingState.Complete
              : LoadingState.Error,
          Error: action.payload.error,
        };
      })
      .addCase(healthCheck.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          return;
        }
        const cameraId = getKey(action.meta.arg.cameraId);
        state.healthCheck[cameraId] = {
          CommandState: LoadingState.Error,
          Error: "Server error",
        };
      })
      .addCase(fetchSnapshot.pending, (state, action) => {
        const cameraId = getKey(action.meta.arg.cameraId);
        const cameraSnapshot = state.snapshots[cameraId];
        if (cameraSnapshot) {
          cameraSnapshot.CommandState = LoadingState.Pending;
          if (action.meta.arg.initial) {
            cameraSnapshot.Image = "";
            cameraSnapshot.SnapshotTime = new Date();
          }
        } else {
          state.snapshots[cameraId] = {
            Image: "",
            CommandState: LoadingState.Pending,
          };
        }
      })
      .addCase(fetchSnapshot.fulfilled, (state, action) => {
        const cameraId = getKey(action.meta.arg.cameraId);
        const cameraSnapshot = state.snapshots[cameraId];
        if (cameraSnapshot) {
          cameraSnapshot.Image = action.payload;
          cameraSnapshot.CommandState = LoadingState.Complete;
          cameraSnapshot.SnapshotTime = new Date();
        }
      })
      .addCase(fetchSnapshot.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          return;
        }
        const cameraId = getKey(action.meta.arg.cameraId);
        const cameraSnapshot = state.snapshots[cameraId];
        if (cameraSnapshot) {
          cameraSnapshot.CommandState = LoadingState.Error;
        }
      })
      .addCase(moveCamera.pending, (state, action) => {
        state.movements[getKey(action.meta.arg.cameraId)] = {
          CommandState: LoadingState.Pending,
        };
      })
      .addCase(moveCamera.fulfilled, (state, action) => {
        state.movements[getKey(action.meta.arg.cameraId)] = {
          CommandState: LoadingState.Complete,
        };
      })
      .addCase(moveCamera.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          return;
        }
        state.movements[getKey(action.meta.arg.cameraId)] = {
          CommandState: LoadingState.Error,
        };
      });
  },
});

export const camerasReducer = camerasSlice.reducer;

export const fetchSnapshot = createAsyncThunk(
  `${camerasSlice.name}/fetchSnapshot`,
  (
    args: { siteId: string; cameraId: ICameraId; initial: boolean },
    { signal }
  ) => Api.fetchSnapshots(args.siteId, args.cameraId, signal)
);

export const healthCheck = createAsyncThunk(
  `${camerasSlice.name}/healthCheck`,
  (args: { siteId: string; cameraId: ICameraId }, { signal }) =>
    Api.healthCheck(args.siteId, args.cameraId, signal)
);

export const moveCamera = createAsyncThunk(
  `${camerasSlice.name}/move`,
  (
    args: { siteId: string; cameraId: ICameraId; x: number; y: number },
    { signal }
  ) => Api.moveCamera(args.siteId, args.cameraId, args.x, args.y, signal)
);
