import { GridColDef } from "@mui/x-data-grid";
import { UserRecordDeleteCellItem } from "./UserRecordDeleteCellItem";
import TableCellItemBool from "../../../common/table/TableCellItemBool";
import TableCellItemString from "../../../common/table/TableCellItemString";
import LightTooltipOnEllipsis from "../../../SolarGikLib/tooltip/LightTooltipOnEllipsis";
import { ComponentEnum, IUserRecord } from "../UserRecordsModels";
import trackerNameClasses from '../../trackers/TrackerName.module.css';
import cellItemClasses from './UserRecordsCellItem.module.css';
import { createTimeCell } from "../../../common/table/CellFactory";
import DateTimeFormatter from "../../app/DateTimeFormatter";

export const userRecordsColumnsNames = {
  Title: "title",
  Date: "eventDate",
  Reporter: "reporter",
  Component: "componentType",
  ComponentId: "componentName",
  RecordType: "recordType",
  Description: "description",
  Id: "id",
  IsEngineerOnly: "isEngineerOnly",
};

export const createUserRecordsTablesColumns =
  (isEngineer: boolean, isSmallScreen: boolean, dateTimeFormatter: DateTimeFormatter): GridColDef[] => {
    const userRecordsTableColumns: GridColDef<IUserRecord>[] = [
      {
        field: userRecordsColumnsNames.Title,
        headerName: "Title",
        flex: 1,
        renderCell: (params) => <TableCellItemString value={params.value} />,
      },
      {
        ...createTimeCell((row) => row.eventDate, dateTimeFormatter, isSmallScreen),
        field: userRecordsColumnsNames.Date,
        headerName: "Date",
      },
      {
        field: userRecordsColumnsNames.Reporter,
        headerName: "Reporter",
        flex: 1,
        renderCell: (params) => {
          return <LightTooltipOnEllipsis title={params.value}>
            <TableCellItemString value={params.value} extraClassName={cellItemClasses.ellipsis} />
        </LightTooltipOnEllipsis>
        },
      },
      {
        field: userRecordsColumnsNames.Component,
        headerName: "Component",
        flex: 1,
        renderCell: (params) => <TableCellItemString value={params.value} />,
      },
      {
        field: userRecordsColumnsNames.ComponentId,
        headerName: "Component Id",
        flex: 1,
        renderCell: (params) => {
          const isTrackerComponent = params.row.componentType === ComponentEnum[ComponentEnum.Tracker];
          const extraClassName = cellItemClasses.ellipsis + ' ' + (isTrackerComponent ? trackerNameClasses['tracker-name'] : '');
          return <LightTooltipOnEllipsis title={params.value}>
            <TableCellItemString value={params.value} extraClassName={extraClassName} />
          </LightTooltipOnEllipsis>
        },
      },
      {
        field: userRecordsColumnsNames.RecordType,
        headerName: "Record Type",
        flex: 1,
        renderCell: (params) => <TableCellItemString value={params.value} />,
      },
      {
        field: userRecordsColumnsNames.Description,
        headerName: "Description",
        flex: 1,
        renderCell: (params) => {
          return <LightTooltipOnEllipsis title={params.value}>
            <TableCellItemString value={params.value} extraClassName={cellItemClasses.ellipsis} />
        </LightTooltipOnEllipsis>
        },
      },
      {
        field: userRecordsColumnsNames.Id,
        headerName: "Actions",
        width: 100,
        sortable: false,
        renderCell: UserRecordDeleteCellItem,
        disableExport: true,
      },
    ];

    if (isEngineer) {
      userRecordsTableColumns.splice(7, 0, {
        field: userRecordsColumnsNames.IsEngineerOnly,
        headerName: "Engineer Only",
        flex: 1,
        renderCell: (params) => <TableCellItemBool value={params.value} />,
      });
    }
    return userRecordsTableColumns;
  };